import Drawer, { DrawerBody, DrawerHeader } from 'common/components/drawer';
import Spinner from 'common/components/general/Spinner';
import Timeline from './Timeline';

import { useEntityLogs } from '@/api/logs/queries';

import React, { FC, ReactNode } from 'react';
import { EntityLogsRequestParams, EntityType } from '@/common/types/logs';
import { DrawerStateType } from '@/ts-common/types/drawer';
import { TimelinePointProps } from './TimelinePoint';

type LogsTimelineDrawerType = {
  drawer: DrawerStateType;
  header?: string;
  subheader?: ReactNode;
  pointComponent?: React.ComponentType<TimelinePointProps>;
  entityId: number | string | null | undefined;
  entityType: EntityType | null;
  entityRequestParams?: EntityLogsRequestParams;
  canBeEnabledWithoutEntity?: boolean;
  excludedEntityActions?: string[];
};

const LogsTimelineDrawer: FC<LogsTimelineDrawerType> = ({
  drawer,
  header = 'Logs',
  subheader,
  pointComponent,
  entityId,
  entityType,
  entityRequestParams = {
    filters: [
      {
        name: 'entity_id',
        operation: 'oneOf',
        value: [entityId]
      },
      {
        name: 'entity_type',
        operation: '=',
        value: entityType
      }
    ]
  },
  canBeEnabledWithoutEntity = false,
  excludedEntityActions = []
}) => {
  const { data, isFetching } = useEntityLogs(
    {
      enabled: drawer.isOpen && ((!!entityId && !!entityType) || canBeEnabledWithoutEntity),
      refetchOnMount: true
    },
    entityRequestParams
  );

  return (
    <Drawer {...drawer}>
      <DrawerHeader>
        <div>{header}</div>
        {subheader ? <div className="fw-bold fs-12 cmt-4">{subheader}</div> : null}
      </DrawerHeader>
      <DrawerBody className="py-3">
        {isFetching ? (
          <div className="h-100p d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        ) : (
          <Timeline
            entityLogs={
              excludedEntityActions.length
                ? data?.filter(log => !excludedEntityActions.includes(log.service_action))
                : data
            }
            pointComponent={pointComponent}
          />
        )}
      </DrawerBody>
    </Drawer>
  );
};

export default LogsTimelineDrawer;
