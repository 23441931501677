import PropTypes from 'prop-types';

export const EventType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
});

const EventDatesPropTypes = {
  is_datetime: PropTypes.bool.isRequired,
  started_at: PropTypes.string.isRequired,
  date_type: PropTypes.string,
  ended_at: PropTypes.string
};

export const EventDates = PropTypes.shape(EventDatesPropTypes);

export const EventModules = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.shape({
      label: PropTypes.string
    })
  })
);

export const Event = PropTypes.shape({
  type: EventType.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modules: EventModules,
  responsible: PropTypes.shape({ full_name: PropTypes.string }),
  vessel: PropTypes.shape({ name: PropTypes.string }),
  port: PropTypes.shape({ name: PropTypes.string }),
  ...EventDatesPropTypes
});

export const EventTemplateType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  started_at: PropTypes.string,
  ended_at: PropTypes.string,
  is_datetime: PropTypes.bool,
  remarks: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  submitted: PropTypes.string,
  submitted_at: PropTypes.string,
  sync_to_onboard: PropTypes.bool,
  updated_at_on_sync_to_onboard: PropTypes.string,
  is_submitted_on_vessel: PropTypes.bool,
  deleted_at: PropTypes.string,
  is_template: PropTypes.bool,
  is_archived: PropTypes.bool,
  active: PropTypes.bool,
  is_scheduled: PropTypes.bool,
  notes: PropTypes.string,
  is_for_vessel: PropTypes.bool,
  at_sea_lat: PropTypes.number,
  at_sea_lon: PropTypes.number,
  uuid: PropTypes.string,
  sync_to_core: PropTypes.bool,
  synced_at: PropTypes.string,
  is_watching: PropTypes.bool,
  signatures_count: PropTypes.number,
  date_type: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  importance: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    sort_index: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    updated_at_on_sync_to_onboard: PropTypes.string,
    deleted_at: PropTypes.string
  }),
  type: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    has_subtype: PropTypes.bool,
    for_performance_reports: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    updated_at_on_sync_to_onboard: PropTypes.string,
    deleted_at: PropTypes.string,
    color: PropTypes.string,
    active: PropTypes.bool,
    hidden_by_default: PropTypes.bool,
    hidden: PropTypes.bool
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  responsible: PropTypes.string,
  port: PropTypes.string,
  itinerary_port: PropTypes.string,
  vessel: PropTypes.string,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sort_index: PropTypes.number,
      uuid: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string
      })
    })
  ),
  submitted_by: PropTypes.string,
  office_attachment_groups: PropTypes.array,
  vessel_attachment_groups: PropTypes.array,
  approvers: PropTypes.array,
  connected_emails: PropTypes.array,
  underwater_service_attachments: PropTypes.array,
  underwater_service_details: PropTypes.string,
  underwater_service_values: PropTypes.array,
  ticketing_case: PropTypes.string,
  signatures: PropTypes.array,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sort_index: PropTypes.number,
      form_uid: PropTypes.number,
      form_id: PropTypes.number,
      form: PropTypes.shape({
        uid: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        is_for_vessel: PropTypes.bool,
        department_id: PropTypes.number,
        importance_id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        deleted_at: PropTypes.string,
        updated_at_on_sync_to_onboard: PropTypes.string,
        id: PropTypes.number,
        file_id: PropTypes.number,
        created_by_id: PropTypes.number,
        revision: PropTypes.string,
        is_current: PropTypes.bool,
        file: PropTypes.string
      }),
      form_submission_id: PropTypes.number,
      form_submission: PropTypes.string
    })
  ),
  office_notes: PropTypes.string,
  checklist: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      event_id: PropTypes.number,
      title: PropTypes.string,
      checked: PropTypes.bool,
      sort_index: PropTypes.number,
      mentions: PropTypes.array,
      comments: PropTypes.array
    })
  )
});
