import TYPES from './types';
import { get } from 'utils/api';
import {
  getDefaultListKey,
  updateDefaultListOptions,
  updateDefaultListTimestamp
} from 'common/utils/lists';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

const aliases = {
  'fuel-grades-with-prices': 'fuel-grades&with_prices=true',
  'special-zones-eca': 'special-zones&type=eca',
  'special-zones-hra': 'special-zones&type=hra',
  'special-zones-vra': 'special-zones&type=vra',
  genders: 'identifiers&type=gender',
  'marital-statuses': 'identifiers&type=marital-status',
  languages: 'identifiers&type=language',
  'language-levels': 'identifiers&type=language-level',
  'blood-types': 'identifiers&type=blood-type',
  'ticket-types': 'identifiers&type=ticket-type',
  'rotation-vessels': 'vessels',
  'evaluation-template-type': 'crew-evaluation-template-types',
  'evaluation-template-reason': 'evaluation-template-reason',
  'shift-types': 'crew-shift-types',
  vessel_groups: 'vessel-groups',
  'captain-report-enums-boiler-colors': 'captain-report-enums&field=blr_color',
  'captain-report-enums-instructed-speed-consumption-by':
    'captain-report-enums&field=instructed_speed_consumption_by',
  'captain-report-enums-instructed-speed-consumption-target':
    'captain-report-enums&field=instructed_speed_consumption_target',
  'captain-report-enums-seabed-types': 'captain-report-enums&field=seabed_type',
  'creatable-captain-report-types': 'captain-report-types&can_create=true',
  'purpose-of-call': 'identifiers&type=purpose-of-call',
  'berth-type': 'identifiers&type=berth-type',
  'ld-stopping-reason': 'captain-report-enums&field=cargo_operation_stoppage_reason_id',
  'ld-hired-gear-type': 'captain-report-enums&field=hired_gear_type_id',
  'stoppage-reason': 'captain-report-enums&field=stoppage_reason',
  'stoppage-associated-element': 'captain-report-enums&field=stoppage_associated_element',
  'status-at-port': 'captain-report-enums&field=status_at_port',
  'party-and-crew-departments': 'departments&include_crew_departments=true',
  'captain-report-drifting-reason': 'captain-report-enums&field=drifting_reason',
  'tank-measurement-method': 'captain-report-enums&field=bs_tank_measurement_method',
  'captain-report-enums-delivered-at': 'captain-report-enums&field=delivered_at',
  'captain-report-enums-holds-on-delivery': 'captain-report-enums&field=holds_on_delivery',
  'captain-report-enums-instructed-speed-allowance-unit':
    'captain-report-enums&field=instructed_speed_allowance_unit',
  'captain-report-enums-instructed-consumption-allowance-unit':
    'captain-report-enums&field=instructed_consumption_allowance_unit',
  'captain-report-enums-phase-shift': 'captain-report-enums&field=dg_power_factor_phase_shift',
  'captain-report-enums-co-base-number': 'captain-report-enums&field=co_base_number',
  'form-fields': 'form-fields',
  'forms-for-vessel': 'forms&is_for_vessel=true',
  'forms-not-in-planning': 'forms&form_in_planning=false',
  'person-ranks': 'person-ranks',
  'crew-ranks-officers': 'crew-ranks&is_officer=true',
  'crew-evaluation-reasons': 'crew-evaluation-reasons',
  'wages-types': 'wages-types',
  'job-restricted-fields': 'job-field-settings'
};

const customEndpointAliases = {
  'saved-table-filters': '/items',
  'digital-forms': '/forms?type=digital',
  'mga-accounts': '/mga/accounts',
  'maintenance-jobs': '/vessel-systems/maintenance-jobs',
  'reports-voyage': '/reports',
  'reports-performance': '/reports',
  'reports-environmental': '/reports',
  'reports-crew': '/reports',
  'reports-purchasing': '/reports',
  'reports-pms': '/reports',
  'reports-mga': '/reports',
  'reports-event': '/reports',
  'reports-forms': '/reports',
  'vetting-questions': '/vetting/questions',
  'tag-types': '/tag-types',
  'tag-filter-types': '/tag-filter-types',
  'tag-sources': '/sources',
  'raw-sources': '/raw-sources',
  sources: '/sources',
  'internal-syncs': '/internal-syncs',
  'raw-tags': '/raw-tags',
  'raw-tags-params': '/raw-tags/params',
  'transformation-functions': '/transformation-functions',
  'ams-tag-groups': '/ams/tag-groups',
  'ams-vessels': '/ams/vessels',
  'forex-rates': '/forex-rates',
  'library-purchasing-categories': '/purchasing-categories'
};

export const getListUrl = list => {
  const hasCustomUrl = customEndpointAliases[list];

  return !hasCustomUrl
    ? `/lists?list=${_get(aliases, list, list)}`
    : `${customEndpointAliases[list]}`;
};

export const fetchListOptions =
  (list, search = '', params = {}) =>
  (dispatch, getState) => {
    const url = `${getListUrl(list)}${search ? `&search=${search}` : ''}`;

    const defaultLists = getState()?.lists?._defaults;
    const defaultListKey = getDefaultListKey(list, params);
    const listState = getState()?.lists?.[list];

    const { lastFetchedAt, isFetching, options } = defaultLists?.[defaultListKey] || {};

    const formattedParams = {
      ...params,
      search
    };

    if (import.meta.env.MODE === 'test')
      return new Promise(resolve => resolve(options || listState?.options));

    if (defaultListKey && !search) {
      if (!lastFetchedAt) {
        dispatch(updateDefaultListTimestamp(list, params));
      } else if (lastFetchedAt && options?.length) {
        if (!_isEqual(listState?.requestParams, formattedParams)) {
          dispatch({
            type: TYPES.FETCH_OPTIONS.SUCCESS,
            payload: { list, options: options }
          });
        }

        return new Promise(resolve => resolve(options));
      } else if (lastFetchedAt && isFetching) {
        return new Promise(resolve => resolve([]));
      }
    }

    dispatch({ type: TYPES.FETCH_OPTIONS.START, payload: { list, params: formattedParams } });

    return get(url, params)
      .then(res => {
        const formattedOptions = res.data.map(item => {
          switch (list) {
            case 'parties':
              return {
                ...item,
                value: item.id,
                label: item.type === 'company' ? item.company_name : item.full_name
              };
            case 'charter-party-duration-types':
              return {
                id: item.label,
                name: item.name
              };

            default:
              return item;
          }
        });
        dispatch({
          type: TYPES.FETCH_OPTIONS.SUCCESS,
          payload: { list, options: formattedOptions }
        });

        if (!search && !options?.length) {
          dispatch(updateDefaultListOptions(list, params, formattedOptions));
        }

        return formattedOptions;
      })
      .catch(error => {
        dispatch({ type: TYPES.FETCH_OPTIONS.ERROR, payload: { list, error } });
        dispatch(updateDefaultListOptions(list, params, []));
      });
  };

export const refetchDeafultListOptions =
  (list, search = '', params) =>
  dispatch => {
    const defaultListKey = getDefaultListKey(list, params);

    dispatch({
      type: TYPES.SET_DEFAULT_LIST,
      payload: {
        list: defaultListKey,
        isFetching: true,
        lastFetchedAt: null
      }
    });

    dispatch(fetchListOptions(list, search, params));
  };
