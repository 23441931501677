import SvgRender from 'common/components/general/SvgRender';
import { useAppSelector } from '@/store/hooks';
import {
  selectItem,
  selectItemIsNewlyAdded
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import doubleArrows from '@/common/assets/svg/actions/double-arrows.svg';
import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';
import { formatDate } from '@/common/utils/dates';
import add from 'common/assets/svg/actions/add.svg';

interface ItemActionsInfoProps {
  itemID: string;
}

const ItemActionsInfo: React.FC<ItemActionsInfoProps> = ({ itemID }) => {
  const { tooltipID, avoidRender } = useTooltipID('show-purchasing-item-replace-info');
  const { tooltipID: addedtooltipID, avoidRender: addedAvoidRender } = useTooltipID(
    'show-purchasing-item-added-info'
  );
  const item = useAppSelector(state => selectItem(state, itemID));
  const showItemInfoAdded = useAppSelector(state => selectItemIsNewlyAdded(state, itemID));

  if (avoidRender || addedAvoidRender) return null;

  return (
    <div className="d-flex align-items-center ms-1">
      {item?.replaced_item_id ? (
        <div className={`d-flex-align-items-center cmt-6`} id={tooltipID}>
          <SvgRender
            className="text-red cursor-pointer"
            src={doubleArrows}
            style={{ width: 14, height: 14 }}
          />
          <Tooltip
            innerClassName="min-width-fit max-width-fit text-start cpt-2"
            target={tooltipID}
            fade={false}
          >
            Item replaced at:&nbsp;
            <strong>{formatDate(item.replaced_at, { time: true })}</strong>
            <br />
            Replaced item:&nbsp;<strong>{item.replaced_item?.description}</strong>
          </Tooltip>
        </div>
      ) : null}

      {showItemInfoAdded && !item?.replaced_item_id ? (
        <div
          className="width-14 height-14 d-flex align-items-center justify-content-center border border-red border-1 border-radius-50"
          id={addedtooltipID}
        >
          <SvgRender
            className="text-red cursor-pointer"
            src={add}
            style={{ width: 8, height: 8 }}
          />
          <Tooltip
            innerClassName="min-width-fit max-width-fit text-start cpt-8"
            target={addedtooltipID}
            fade={false}
          >
            Item created at:&nbsp;
            <strong>{formatDate(item?.created_at, { time: true })}</strong>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default ItemActionsInfo;
