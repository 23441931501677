import { FC } from 'react';
import clsx from 'clsx';

import { useAppSelector } from '@/store/hooks';
import Information from '@/ts-common/components/general/Information';
import { numberToStr } from '@/ts-common/utils/numbers';
import { selectRequisitionStatusLabel } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import {
  TotalBlackBoxType,
  shouldRenderComponent
} from '@/common/components/purchasing/requisition/header/components/total-black-box/helpers.ts';

type TotalBlackBoxProps = {
  title: string;
  value: string | undefined;
  tooltip: string;
  className?: string;
  type: TotalBlackBoxType;
};

const TotalBlackBox: FC<TotalBlackBoxProps> = ({ title, value, type, tooltip, className }) => {
  const status = useAppSelector(selectRequisitionStatusLabel);

  if (shouldRenderComponent(type, status))
    return (
      <div
        className={clsx(
          'd-flex max-h-38 flex-column bg-black border-radius-7 min-w-104 text-white cpy-4 px-1',
          className
        )}
      >
        <div className="d-flex align-items-center">
          <div className="fs-10 flex-1">{title}</div>

          <Information
            message={tooltip}
            tooltipClassname="min-w-fit max-w-fit"
            svgStyle={{ width: 10, height: 10 }}
          />
        </div>

        <div className="fs-12 fw-bold">{numberToStr(value, 2, 2)}</div>
      </div>
    );
};

export default TotalBlackBox;
