import _isNumber from 'lodash/isNumber';
import _isArray from 'lodash/isArray';
import { useEffect, useCallback } from 'react';

import * as listActions from 'store/lists/actions';

import { selectListFromStore } from 'store/lists/selectors';
import { useSelector } from 'react-redux';
import useActions from 'common/utils/hooks/useActions';

import _isObject from 'lodash/isObject';
import _debounce from 'lodash/debounce';

const DrillDropdownView = ({ value, seperator = ', ' }) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const drills = useSelector(state => selectListFromStore(state, 'drills'));

  const fetchOptions = (selectedValue, drillsList) => {
    if (
      selectedValue &&
      !_isObject(selectedValue) &&
      !drillsList.isFetching &&
      !drillsList.options?.length
    ) {
      fetchListOptions('drills');
    }
  };

  const debouncedFetchOptions = useCallback(_debounce(fetchOptions, Math.random() * 1200), []);

  useEffect(() => {
    debouncedFetchOptions(value, drills);
  }, [value, drills]);

  const parsedValue = _isNumber(value)
    ? drills.options.find(d => d.id === value)?.name || '-'
    : _isArray(value)
    ? value.map((v, i) => `${v?.name}${i === value?.length - 1 ? '' : seperator}`)
    : value?.name || '-';

  return parsedValue;
};

export default DrillDropdownView;
