import TYPES from 'common/components/table/store/types';
import { get } from 'utils/api';
import _get from 'lodash/get';

import { parse } from './payloadParser';

const aliases = {
  'market-indexes': 'market-indexes/values',
  'bunker-prices': 'bunkering-prices',
  companies: 'parties/company',
  accounting_companies_settings: 'companies',
  persons: 'parties/person',
  parties_can_login: 'parties/can-login',
  crew: 'crew',
  crew_planning: 'crew-rotation',
  crew_planning_history: 'crew-rotation',
  crew_document_types: 'crew-document-types',
  crew_matrices: 'crew-matrices',
  crew_evaluation_obligation_templates: 'crew-evaluation-obligation-templates',
  crew_evaluation_criteria: 'crew-evaluation-criteria',
  crew_evaluation_criteria_categories: 'crew-evaluation-criteria/categories',
  crew_evaluation_obligations: 'crew-evaluation-obligations',
  crew_skill_types: 'settings/crew/crew-skill-types',
  crew_termination_reasons: 'crew-terminations-reasons',
  crew_ticketing_types: 'ticketing-types',
  crew_holidays: 'crew-holidays',
  crew_ranks: 'crew-ranks',
  crew_list: 'crew-list',
  crew_seamen_contracts: 'contracts',
  jobs_list: 'jobs',
  pms_jobs_list: 'vessel-systems/maintenance-jobs',
  pms_job_prototypes: 'vessel-systems/maintenance-job-prototypes',
  pms_system_attributes: 'vessel-system-attribute-groups',
  pms_spare_parts: 'spare-parts',
  pms_job_types: 'vessel-systems/maintenance-job-types',
  pms_system_groups: 'vessel-systems/groups/table',
  pms_vessel_systems: 'vessel-systems/table',
  pms_vessel_system_assignments: 'vessel-systems/assignments',
  jobs_templates: 'job-templates',
  jobs_statuses: 'job-statuses',
  jobs_triggers: 'job-triggers',
  jobs_restricted: 'jobs/settings/fields',
  captain_reports: 'captain-reports',
  captain_report_templates: 'captain-reports/templates',
  company_types: 'companies/types',
  vessel_groups: 'vessel-groups',
  vessel_shares: 'vessel-shares',
  vessel_share_requests: 'vessel-share-requests',
  persons_can_login: 'parties/can-login?type=person',
  crew_can_login: 'parties/can-login?type=crew',
  vessel_document_reminders: 'vessel-document-reminders',
  ticketing_events: 'event-ticketing-cases',
  ticketing_crew: 'crew-ticketing-cases',
  ticketing_tickets: 'ticket-offers',
  purchasing_categories: 'purchasing-categories',
  purchasing_packing: 'purchasing-units',
  purchasing_unit_types: 'purchasing-unit-types',
  purchasing_pricelists: 'price-lists',
  purchasing_budgeted_quantity: 'purchasing-budgets',
  purchasing_library_setup_list: 'stores/tables',
  past_promotions: 'reports/past-promotions',
  planned_promotions: 'reports/planned-promotions',
  reports_crew: 'reports',
  reports_voyage: 'reports',
  reports_pms: 'reports',
  reports_event: 'reports',
  reports_overdue_jobs: 'reports/overdue-jobs',
  reports_job_statistics: 'reports/job-statistics',
  reports_purchasing_requested_but_not_delivered: 'reports/requested-but-not-delivered',
  reports_crew_wages: 'reports/crew-wages',
  reports_class_related_components: 'reports/class-related-components',
  reports_crew_medical_cases: 'reports/crew-medical-cases',
  reports_main_engine_piston_and_cylinder_liner_wear_down:
    'reports/main-engine-piston-and-cylinder-liner-wear-down',
  reports_running_hours_summary: 'reports/running-hours-summary',
  reports_past_promotions: 'reports/past-promotions',
  reports_promotions: 'reports/promotions',
  reports_lubricants_supply_monitoring: 'reports/lubricants-supply-monitoring',
  reports_lubricants_remaining_days_estimator: 'reports/lubricants-remaining-days-estimator',
  reports_lubricants_fleet_consumption: 'reports/lubricants-fleet-consumption',
  reports_fresh_water_generator_performance: 'reports/fresh-water-generator-performance',
  reports_fresh_water_generator_performance_fleet:
    'reports/fresh-water-generator-performance-fleet',
  reports_change_logs: 'reports/crew-change-logs',
  reports_evaluations: 'reports/evaluations',
  reports_onboard_nationalities: 'reports/onboard-nationalities',
  reports_overlapping_days: 'reports/overlapping-days',
  reports_training_needs: 'reports/training-needs',
  reports_trainings: 'reports/trainings',
  reports_vaccinations: 'reports/vaccination-report',
  reports_not_for_reemployment: 'reports/not-for-reemployment',
  reports_newly_hired: 'reports/newly-hired',
  reports_retention_rate_intertanko: 'crew-terminations-intertanko',
  reports_retention_rate: 'crew-terminations',
  reports_mrv: 'reports/mrv',
  reports_imo_dcs: 'reports/imo-dcs',
  reports_stern_tube_report: 'reports/stern-tube-report',
  reports_cii_reference_lines: 'reports/cii-reference-lines',
  reports_cii_fleet_running_cps: 'reports/cii-fleet-running-cps',
  reports_cii_fleet_for_one_year: 'reports/cii-fleet-year',
  reports_running_cps_redelivery_dates: 'reports/running-cps-redelivery-dates',
  reports_cii_fleet_running_legs: 'reports/cii-fleet-running-legs',
  reports_port_statement_bunkering_and_bunker_survey:
    'reports/port-statement-bunkering-and-bunker-survey',
  reports_captain_reports_bunkers: 'reports/captain-reports-bunkers',
  reports_port_cagoes: 'reports/ports-and-cargoes',
  reports_weather_analysis: 'reports/weather-analysis',
  reports_lub_oil_analysis: 'reports/lubricant-oil-analysis',
  reports_attendance_events: 'reports/attendance-events',
  reports_underwater_services: 'reports/underwater-services',
  reports_fixtures: 'reports/fixtures',
  reports_vetting_overview: 'reports/vetting-overview',
  reports_vessel_overall_condition: 'reports/vessel-overall-condition',
  reports_underwater_services_fleet: 'reports/underwater-services-fleet-report',
  reports_crew_medical_statistics: 'reports/crew-medical-statistics',
  reports_crew_wages_types: 'reports/wages/wage-type',
  reports_crew_wages_ranks: 'reports/wages/crew-rank',
  reports_crew_wages_vessel: 'reports/wages/vessel',
  reports_ticketing_ticketing_type: 'reports/ticketing/ticketing-type',
  reports_ticketing_rank: 'reports/ticketing/rank',
  reports_ticketing_nationality: 'reports/ticketing/nationality',
  reports_crew_medical_case_categories: 'reports/crew-medical-case-categories',
  reports_port_documents: 'reports/port-documents',
  reports_root_cause_analysis_statistics: 'reports/root-cause-analysis-statistics',
  reports_agents_appointed: 'reports/agents-appointed',
  reports_audits_and_inspections_statistics:
    'reports/audits-and-inspections-statistics/findings-per-tag',
  form_submissions: 'form-submissions',
  digital_forms: 'forms?type=digital',
  upload_forms: 'forms?type=upload',

  form_fields: 'form-fields',
  form_obligation_settings: 'form-obligation-settings',
  form_obligations: 'form-obligations',
  review_process_templates: 'review-process-templates',
  review_process_list: 'review-processes',
  onboard_syncs: 'onboard-sync-jobs',
  document_manager_crew: 'document-manager/crew',
  document_manager_crew_documents: 'document-manager',
  form_statuses: 'form-statuses',
  event_statuses: 'event-statuses',
  event_accounts_categories: 'event-account-categories',
  events_audits_types: 'events/audits/types',
  events_audits_inspections: 'events/audits',
  events_audits_reasons: 'events/audits/reasons',
  events_audits_evaluations: 'events/audits/evaluation-criteria',
  finding_categories: 'findings/categories',
  finding_subcategories: 'findings/subcategories',
  finding_tags: 'findings/tags',
  event_templates: 'event-templates',
  event_underwater_service_types: 'events/underwater-service-types',
  events_accounts: 'events/accounts',
  event_off_hire: 'events/off-hire-reasons',
  events_linked_purchase_cases: 'events/requisitions',
  events_underwater_services: 'events/underwater-services',
  events_lub_oil_analysis_settings: 'vessel-systems/assignments/lub-oil-analysis',
  risk_assessment_categories: 'risk-assessments/categories',
  risk_assessment_operations: 'risk-assessments/operations',
  form_planning_settings: 'form-planning-settings',
  form_planning: 'form-planning',
  purchasing_requisitions: 'purchasing-requisitions',
  purchasing_requisitions_ev: 'purchasing-requisitions/suppliers',
  purchasing_forwarding_items: 'forwarding-cases/items',
  purchasing_forwarding_orders: 'forwarding-cases/suppliers',
  purchasing_forwarding_cases: 'forwarding-cases',
  accounting_ledger_cards: 'accounts',
  budgeting_report_setup: 'budgeting-reports',
  mga_action_types: 'mga/action-types',
  mga_overview: 'mga',
  mga_inventory_items_overview: 'mga/items/overview',
  mga_inventory_items_additions: 'mga/items/additions',
  mga_inventory_items_removals: 'mga/items/removals',
  accounting_account_types: 'account-contract-types',
  accounting_budget: 'budgets',
  accounting_invoices: 'invoices',
  accounting_invoices_tickets: 'ticket-offers',
  accounting_invoices_requisition_suppliers: 'purchasing-requisitions/suppliers',
  accounting_invoices_da_agents: 'port-da-case-agents',
  accounting_invoices_da_agent_items: 'port-da-case-agent-items',
  accounting_invoices_events_accounts: 'events/accounts',
  accounting_invoices_events_crew_medical_cases_expenses: 'events/medical-cases-expenses',
  accounting_budget_scenarios: 'budget-scenarios',
  accounting_contracts: 'account-contracts',
  root_causes: 'root-causes',
  root_cause_categories: 'root-cause-categories',
  it_dashboard: 'orca/info',
  charter_parties: 'charter-parties',
  inventory: 'inventory',
  vetting_setup: 'vetting/questions',
  events_calendar: 'events',
  events_overview: 'fleet-events',
  claims_expenses: 'events/medical-expenses',
  news_announcements: 'settings/posts',
  captain_reports_running_hours: 'captain-reports',
  reports_findings: 'reports/findings',
  medical_cases_types: 'events/crew-medical-case-types',
  medical_cases_categories: 'events/crew-medical-case-categories',
  notification_preferences_administration: 'parties/person',
  reports_crew_promotions_contracts_seamen: 'reports/crew-promotions-contracts/table',
  case_tags: 'settings/case-tags',
  port_da_items: 'port-da-items',
  port_da_cases: 'port-da-cases',
  freight_hire_collection_items: 'freight-hire-collection-items',
  freight_hire_invoices: 'freight-hire-collection-invoices'
};

const fixedParams = {};

export const getTableList = params => (dispatch, getState) => {
  const { table, loadMore, ...rest } = params;
  let requestParams = { ...rest };
  let previousTableData = [];

  if (fixedParams[table]) {
    requestParams = { ...requestParams, ...fixedParams[table] };
  }

  if (loadMore) {
    // infinite scrolling implementation
    const { pendingRequest, paging, data } = getState().tables.lists[table];
    const { current_page, last_page } = paging;

    if (pendingRequest || current_page >= last_page) return;

    requestParams.paging.current_page = current_page + 1;
    previousTableData = [...data];
  }

  dispatch({ type: TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

  return get(`/${_get(aliases, table, table)}`, requestParams)
    .then(response => {
      if (
        params &&
        params.paging &&
        response.data.meta &&
        parseInt(params.paging.current_page) > response.data.meta.last_page
      ) {
        dispatch(
          getTableList({
            ...params,
            paging: { ...params.paging, current_page: parseInt(params.paging.current_page) - 1 }
          })
        );
      } else {
        dispatch({
          type: TYPES.GET_TABLE_LIST.SUCCESS,
          payload: {
            data: parse(
              loadMore
                ? { ...response.data, data: [...previousTableData, ...response.data.data] }
                : response.data,
              params,
              table
            ),
            table
          }
        });
      }

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } }));
};

export {
  updateTableRow,
  removeTableRow,
  resetTableRowUpdate,
  setTableWarnings,
  setTableTotals
} from 'common/components/table/store/actions';
