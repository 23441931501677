import React, { useEffect, useState } from 'react';
import PageTitle from 'common/components/general/PageTitle';

import SavedSearchesSelector from './SavedSearchesSelector';
import { getOnboardSetupFilterPreset } from '@/api/setup-onboard/api.ts';

import useRouter from 'use-react-router';
import _isEqual from 'lodash/isEqual';

import { useSelector } from 'react-redux';
import { parseQueryParams } from 'utils/urls';
import { selectTableListInvalid } from 'common/components/table/store/selectors';

import { getTableSearch } from 'store/tables/actions';
import { setTableSearch } from '@/store/tables/actions-ts.ts';
import { useAppDispatch } from '@/store/hooks';
import { isAuthorized } from 'utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';
import TitleLayoutActions from './TitleLayoutActions';
import { hashValue } from '@/common/utils/parsing';

const Title = ({
  pageTitle,
  savedSearchParam,
  tableSearch,
  tableIsInvalid,
  account,
  setModalOpened,
  queryChanged,
  label,
  isSetupOnboard
}) => {
  const canEditOnboardSetupByPermissions = isAuthorized(account, [
    permissions.office.settings.onboard_setup.filter_presets.edit
  ]);
  const canEditOnboardSetup = isSetupOnboard && canEditOnboardSetupByPermissions;

  return (
    <div className="page-table-layout--header-title d-flex align-items-center">
      {pageTitle}
      <SavedSearchesSelector label={label} isSetupOnboard={isSetupOnboard} />

      {!isSetupOnboard || canEditOnboardSetup ? (
        <TitleLayoutActions
          queryChanged={queryChanged}
          setModalOpened={setModalOpened}
          savedSearchParam={savedSearchParam}
          tableSearch={tableSearch}
          tableIsInvalid={tableIsInvalid}
          isSetupOnboard={isSetupOnboard}
          label={label}
        />
      ) : null}
    </div>
  );
};

const TitleLayout = ({ title, setModalOpened, label, isSetupOnboard }) => {
  const [queryChanged, setQueryChanged] = useState(false);
  const [savedSearchParam, setSavedSearchParam] = useState(null);
  const dispatch = useAppDispatch();

  const account = useSelector(state => state.account);
  const { history } = useRouter();

  const tableIsInvalid = useSelector(state => selectTableListInvalid(state, label));

  const tableSearch = useSelector(state => {
    const { searchId } = parseQueryParams(history.location.search);

    if (searchId) return state.tables.search;
    else return null;
  });

  useEffect(() => {
    const { searchId, ...rest } = parseQueryParams(history.location.search);

    if (
      history.location.search &&
      history.location.search !== '?' &&
      history.location.search !== ''
    ) {
      if (searchId) {
        if (searchId !== savedSearchParam) {
          setSavedSearchParam(searchId);
        }

        if (tableSearch) checkIfSearchHasChanged();
      } else if (Object.keys(rest).length) {
        setQueryChanged(true);
        setSavedSearchParam(null);
      }
    } else {
      setQueryChanged(false);
      setSavedSearchParam(null);
    }
  }, [history.location.search]);

  useEffect(() => {
    checkIfSearchHasChanged();
  }, [tableSearch]);

  useEffect(() => {
    if (savedSearchParam) {
      const fetchTableSearch = async () => {
        let res = [];
        if (isSetupOnboard) {
          res = await getOnboardSetupFilterPreset({ id: savedSearchParam });
          dispatch(setTableSearch(res));
        } else {
          res = await dispatch(getTableSearch({ id: savedSearchParam }));
        }

        if (!res) {
          setSavedSearchParam(null);
          setQueryChanged(true);
        }
      };
      fetchTableSearch();
    }
  }, [dispatch, isSetupOnboard, savedSearchParam]);

  const checkIfSearchHasChanged = () => {
    if (tableSearch) {
      const { searchId, ...rest } = parseQueryParams(history.location.search);
      const searchParams = {
        ...rest,
        page_size: rest.page_size ? parseInt(rest.page_size) : undefined
      };
      const {
        filters,
        visible,
        sorting,
        page_size,
        compact,
        sizing,
        search: searchText
      } = tableSearch;

      if (!searchParams.filters) searchParams.filters = null;
      if (!searchParams.sorting) searchParams.sorting = null;
      if (!searchParams.visible) searchParams.visible = null;
      if (!searchParams.compact)
        compact ? (searchParams.compact = true) : (searchParams.compact = false); // We need this because in the API this is by default true so we need to do a proper comparison
      if (!searchParams.sizing) searchParams.sizing = null;
      if (!searchParams.searchText) searchParams.searchText = null;

      const objectToCompare = {
        filters,
        visible,
        sorting,
        compact,
        sizing,
        searchText,
        page_size: page_size ? parseInt(page_size) : undefined
      };

      if (
        searchId &&
        (hashValue(searchParams) !== hashValue(objectToCompare) ||
          tableSearch.id != savedSearchParam)
      ) {
        setQueryChanged(true);
      } else {
        setQueryChanged(false);
      }
    }
  };

  return (
    <PageTitle
      render={
        <Title
          savedSearchParam={savedSearchParam}
          tableSearch={tableSearch}
          tableIsInvalid={tableIsInvalid}
          account={account}
          pageTitle={title}
          setModalOpened={setModalOpened}
          queryChanged={queryChanged}
          label={label}
          isSetupOnboard={isSetupOnboard}
        />
      }
    />
  );
};

export default TitleLayout;
