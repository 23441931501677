import { FC, useState } from 'react';

import ModuleWrapper from '../components/ModuleWrapper';
import { useAppSelector } from '@/store/hooks';
import { selectIsTemplate } from '@/events/store/events/selectors';
import EditMode from './EditMode';
import ViewMode from './ViewMode';
import linkIcon from '@/common/assets/svg/common/link.svg';
import stoppageIcon from '@/captain-reports/assets/svg/stoppage-fillable.svg';
import { useGetEventOffHireQuery } from '@/api/events/event-modules/off-hire/queries';
import { convertMinutesToHoursAndMinutes } from '@/common/utils/dates';
import paths from '@/routing/routes/_paths';
import { stringifyObj } from '@/ts-common/utils/urls';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import LinkButton from './LinkButton';

type OffHireProps = {
  isCreate: boolean;
};

const OffHire: FC<OffHireProps> = ({ isCreate }) => {
  const [isEditing, setIsEditing] = useState(false);

  const isTemplate: boolean = useAppSelector(selectIsTemplate);

  const { data: offHireData } = useGetEventOffHireQuery();

  const { hours, minutes } = convertMinutesToHoursAndMinutes(
    offHireData?.event.stoppage_report?.stoppage_duration?.value || 0
  );

  const firstInvoice = offHireData?.event.freight_hire_collection_invoices[0];
  const stoppageReport = offHireData?.event.stoppage_report;
  const hasInvoice = !!offHireData?.event.freight_hire_collection_invoices.length;

  return (
    <ModuleWrapper
      className="event-off-hire"
      type="off_hire"
      action={
        <div className="d-flex align-items-center w-90p position-relative">
          {!!stoppageReport?.id && (
            <div className="d-flex align-items-center fs-12 fw-medium text-primary">
              Stoppage Duration:
              {stoppageReport.stoppage_duration?.value ? `${hours}h ${minutes}m` : '-'}
              &nbsp; - &nbsp;
              {stoppageReport.stoppage_duration?.value
                ? `${(
                    stoppageReport.stoppage_duration.value /
                    60 / // convert minutes to hours
                    24
                  ) // convert hours to days
                    .toFixed(6)} days`
                : ''}
            </div>
          )}

          {hasInvoice && (
            <div
              className={`d-flex align-items-center fs-12 fw-medium text-primary ${
                stoppageReport?.id ? 'ms-3' : ''
              }`}
            >
              Active Charter Party: {firstInvoice?.charter_party.charterer_description}
            </div>
          )}

          <div
            className="d-flex align-items-center position-absolute"
            style={{ right: -13, top: 0 }}
          >
            <AuthCheck permissions={[permissions.office.voyages.freight_hire.view]}>
              <LinkButton
                disabled={!hasInvoice}
                onClick={() =>
                  window.open(
                    `${paths.freight_hire}/${firstInvoice?.id}?${stringifyObj({
                      filters: [
                        {
                          name: 'vessel_id',
                          operation: '=',
                          value: firstInvoice?.charter_party.vessel_id
                        },
                        {
                          name: 'charter_party_id',
                          operation: '=',
                          value: firstInvoice?.charter_party.id
                        }
                      ]
                    })}`
                  )
                }
                image={linkIcon}
                text="Go to the Invoice"
              />
            </AuthCheck>
            <AuthCheck permissions={[permissions.office.voyages.captain_reports.view]}>
              <LinkButton
                className="ms-1"
                disabled={!stoppageReport}
                onClick={() => window.open(`${paths.captain_reports}/${stoppageReport?.id}`)}
                image={stoppageIcon}
                text="Go to the Stoppage Report"
              />
            </AuthCheck>
          </div>
        </div>
      }
    >
      {!isTemplate && !isCreate && (
        <>
          {isEditing ? (
            <EditMode setIsEditing={setIsEditing} />
          ) : (
            <ViewMode setIsEditing={setIsEditing} />
          )}
        </>
      )}
    </ModuleWrapper>
  );
};

export default OffHire;
