import React from 'react';
import { Col } from 'reactstrap';

import { AttachmentsInput, AttachmentsView } from './components/attachments';
import { BooleanInput, BooleanView } from './components/boolean';
import { CrewParticipantsInput, CrewParticipantsView } from './components/crew-participants';
import { DateRangeInput, DateRangeView } from './components/date-range';
import { DatetimeInput, DatetimeView } from './components/datetime';
import { DepartmentsInput, DepartmentsView } from './components/departments';
import { DrillDropdownInput, DrillDropdownView } from './components/drill-dropdown';
import { TrainingDropdownInput, TrainingDropdownView } from './components/training-dropdown';
import { DropdownInput, DropdownView } from './components/dropdown';
import {
  DropdownWithDetailsInput,
  DropdownWithDetailsView
} from './components/dropdown-with-details';
import {
  LeaderOfInvestigationInput,
  LeaderOfInvestigationView
} from './components/leader-of-investigation';
import { PlaceOfOccurenceInput, PlaceOfOccurenceView } from './components/place-of-occurence';
import { RadioInput, RadioView } from './components/radio';
import { RadioWithDetailsInput, RadioWithDetailsView } from './components/radio-with-details';
import { StringInput, StringView } from './components/string';
import { TextInput, TextView } from './components/text';
import { NumberInput, NumberView } from './components/number';
import { TimeInput, TimeView } from './components/time';
import { VesselsInput, VesselsView } from './components/vessels';
import { TextEditorInput, TextEditorView } from './components/text-editor';
import {
  CheckboxWithDetailsInput,
  CheckboxWithDetailsView
} from './components/checkbox-with-details';
import { DateInput, DateView } from './components/date';
import { DatetimeRangeInput, DatetimeRangeView } from './components/datetime-range';
import { DatesMultipleInput, DatesMultipleView } from './components/dates-multiple';
import { OfficeUserInput, OfficeUserView } from './components/office-user';
import {
  DropdownMultipleSelectInput,
  DropdownMultipleSelectView
} from './components/dropdown-multiple-select';
import { FormInstructionsImageView } from './components/form-instructions-image';
import { RootCauseAnalysisInput, RootCauseAnalysisView } from './components/root-cause-analysis';
import { VesselSystemAttributeView } from './components/vessel-system-attribute';

import Title from './components/Title';
import HTML from './components/HTML';
import Table from './components/table';
import ManualChapter from './components/ManualChapter';

import {
  getFormFieldID,
  getFormFieldParams,
  getFormFieldParamsOptions,
  getFormFieldParamsText,
  getFormFieldParentID,
  getFieldTypeLabel,
  getFieldName
} from 'common/components/forms/digital/state-utils';
import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import { InventorySurveyRobView, InventorySurveyRobInput } from './components/inventory-survey-rob';
import { RiskAssessmentInput, RiskAssessmentView } from './components/risk-assessment';
import { FindingInput, FindingView } from './components/finding';

export const customFieldRows = [
  FORM_FIELD_TYPES.title.label,
  FORM_FIELD_TYPES.subtitle.label,
  FORM_FIELD_TYPES.instructions.label,
  FORM_FIELD_TYPES.table_with_fixed_rows.label,
  FORM_FIELD_TYPES.crew_participants.label,
  FORM_FIELD_TYPES.table_with_dynamic_rows.label,
  FORM_FIELD_TYPES.risk_assessment.label
];

export const renderInputType = ({
  formSubmission,
  formField,
  state,
  setState,
  disabled,
  inputComponentProps = {},
  headerFormState,
  fields,
  isFormForVessel
}) => {
  const fieldTypeLabel = getFieldTypeLabel(formField);
  const formFieldId = getFormFieldID(formField);
  const formFieldParams = getFormFieldParams(formField);
  const formFieldParentID = getFormFieldParentID(formField);

  const columnWidth = formFieldParentID ? 12 : inputComponentProps.columnWidth || null;

  const inputProps = {
    formField,
    formSubmission,
    formFieldId: formFieldId,
    formFieldParams,
    type: fieldTypeLabel,
    state,
    setState,
    disabled,
    onChange: value =>
      disabled ? null : setState(prev => ({ ...prev, [formFieldId]: { value, error: '' } })),
    error: disabled || !state ? null : state[formFieldId]?.error,
    value: disabled || !state ? null : state[formFieldId]?.value,
    headerFormState,
    isFormForVessel,
    fields,
    ...inputComponentProps
  };

  switch (fieldTypeLabel) {
    case FORM_FIELD_TYPES.attachments.label:
      return (
        <Col xs={columnWidth || 9}>
          <AttachmentsInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.boolean.label:
      return (
        <Col>
          <BooleanInput formField={formField} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.crew_participants.label:
      return (
        <Col xs={12}>
          <CrewParticipantsInput formField={formField} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.date.label:
      return (
        <Col xs={columnWidth || 2}>
          <DateInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.datetime.label:
      return (
        <Col xs={columnWidth || 2}>
          <DatetimeInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.date_range.label:
      return (
        <Col>
          <DateRangeInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.datetime_range.label:
      return (
        <Col>
          <DatetimeRangeInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.dates_multiple.label:
      return (
        <Col>
          <DatesMultipleInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.departments_dropdown.label:
      return (
        <Col xs={columnWidth || 9}>
          <DepartmentsInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.drill_dropdown.label:
      return (
        <Col xs={columnWidth || 3}>
          <DrillDropdownInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.training_dropdown.label:
      return (
        <Col xs={columnWidth || 3}>
          <TrainingDropdownInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown.label:
      return (
        <Col xs={columnWidth || 3}>
          <DropdownInput options={getFormFieldParamsOptions(formField)} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown_with_details.label:
      return (
        <Col xs={columnWidth || 5}>
          <DropdownWithDetailsInput
            options={getFormFieldParamsOptions(formField)}
            {...inputProps}
          />
        </Col>
      );

    case FORM_FIELD_TYPES.checkbox_with_details.label:
      return (
        <Col xs={columnWidth || 5}>
          <CheckboxWithDetailsInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.crew_with_rank.label:
      return (
        <Col xs={12}>
          <LeaderOfInvestigationInput formField={formField} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.place.label:
      return (
        <Col xs={columnWidth || 9}>
          <PlaceOfOccurenceInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.radio_with_details.label:
      return (
        <Col className="d-flex align-items-center">
          <RadioWithDetailsInput options={getFormFieldParamsOptions(formField)} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.radio.label:
      return (
        <Col className="radio d-flex align-items-center flex-wrap">
          <RadioInput options={getFormFieldParamsOptions(formField)} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.string.label:
      return (
        <Col xs={columnWidth || 3}>
          <StringInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.text.label:
      return (
        <Col xs={columnWidth || 5}>
          <TextInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.number.label:
      return (
        <Col xs={columnWidth || 5}>
          <NumberInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.time.label:
      return (
        <Col xs={columnWidth || 2}>
          <TimeInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.vessels_dropdown.label:
      return (
        <Col xs={columnWidth || 9}>
          <VesselsInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.text_with_editor.label:
      return (
        <Col xs={columnWidth || 9}>
          <TextEditorInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.form_instructions_image.label:
      return (
        <Col xs={columnWidth || 12} className="form-field-view">
          <FormInstructionsImageView formFieldParams={getFormFieldParams(formField)} />
        </Col>
      );

    case FORM_FIELD_TYPES.title.label:
    case FORM_FIELD_TYPES.subtitle.label:
      return (
        <Title
          value={getFormFieldParamsText(formField)}
          isSubTitle={FORM_FIELD_TYPES.subtitle.label === fieldTypeLabel}
        />
      );

    case FORM_FIELD_TYPES.instructions.label:
      return (
        <HTML
          title={getFieldName(formField)}
          value={getFormFieldParamsText(formField)}
          parentId={formFieldParentID}
        />
      );

    case FORM_FIELD_TYPES.table_with_fixed_rows.label:
      return <Table formField={formField} {...inputProps} />;

    case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
      return <Table formField={formField} {...inputProps} dynamic />;

    case FORM_FIELD_TYPES.office_user.label:
      return (
        <Col xs={columnWidth || 9}>
          <OfficeUserInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.manual_chapter.label:
      return (
        <Col>
          <ManualChapter formField={formField} />
        </Col>
      );

    case FORM_FIELD_TYPES.inventory_survey_rob.label:
      return (
        <Col>
          <InventorySurveyRobInput formField={formField} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown_multiple_select.label:
      return (
        <Col xs={columnWidth || 8}>
          <DropdownMultipleSelectInput
            options={getFormFieldParamsOptions(formField)}
            {...inputProps}
          />
        </Col>
      );

    case FORM_FIELD_TYPES.root_cause_analysis.label:
      return (
        <Col xs={columnWidth || 8}>
          <RootCauseAnalysisInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.risk_assessment.label:
      return (
        <Col xs={12}>
          <RiskAssessmentInput formField={formField} {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.findings.label:
      return (
        <Col xs={columnWidth || 12}>
          <FindingInput {...inputProps} />
        </Col>
      );

    case FORM_FIELD_TYPES.vessel_system_attribute.label:
      return (
        <Col xs={columnWidth || 8}>
          <VesselSystemAttributeView formField={formField} />
        </Col>
      );

    default:
      return null;
  }
};

export const renderViewType = (formField, value, formValues, formFields, formPreviousValues) => {
  const fieldTypeLabel = getFieldTypeLabel(formField);
  const formFieldParentID = getFormFieldParentID(formField);
  const formFieldID = getFormFieldID(formField);

  const columnWidth = formFieldParentID ? 12 : null;

  switch (fieldTypeLabel) {
    case FORM_FIELD_TYPES.attachments.label:
      return (
        <Col xs={columnWidth || 8} className="form-field-view text-primary fs-12">
          {value && value.length ? <AttachmentsView fieldID={formFieldID} value={value} /> : '-'}
        </Col>
      );

    case FORM_FIELD_TYPES.boolean.label:
      return (
        <Col className="form-field-view text-primary fs-12">
          <BooleanView formField={formField} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.crew_participants.label:
      return (
        <Col xs={columnWidth || 8}>
          <CrewParticipantsView formField={formField} label={fieldTypeLabel} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.date.label:
      return (
        <Col xs={columnWidth || 6} className="d-flex fs-12 form-field-view text-primary">
          <DateView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.datetime.label:
      return (
        <Col xs={columnWidth || 6} className="d-flex fs-12 form-field-view text-primary">
          <DatetimeView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.date_range.label:
      return (
        <Col xs={columnWidth || 6} className="form-field-view text-primary d-flex fs-12">
          <DateRangeView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.datetime_range.label:
      return (
        <Col xs={columnWidth || 6} className="form-field-view text-primary d-flex fs-12">
          <DatetimeRangeView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.dates_multiple.label:
      return (
        <Col>
          <DatesMultipleView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.departments_dropdown.label:
      return (
        <Col xs={columnWidth || 9}>
          <DepartmentsView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.drill_dropdown.label:
      return (
        <Col className="form-field-view text-primary fs-12 text-pre-wrap">
          <DrillDropdownView value={value} seperator={`\n`} />
        </Col>
      );

    case FORM_FIELD_TYPES.training_dropdown.label:
      return (
        <Col xs={columnWidth || 6} className="form-field-view text-primary fs-12 text-pre-wrap">
          <TrainingDropdownView value={value} seperator={`\n`} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown.label:
      return (
        <Col xs={columnWidth || 6} className="form-field-view text-primary d-flex fs-12">
          <DropdownView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown_with_details.label:
      return (
        <Col xs={columnWidth || 3}>
          <DropdownWithDetailsView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.crew_with_rank.label:
      return (
        <Col xs={columnWidth || 8}>
          <LeaderOfInvestigationView label={fieldTypeLabel} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.place.label:
      return (
        <Col xs={columnWidth || 8} className="form-field-view text-primary fs-12">
          <PlaceOfOccurenceView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.radio.label:
      return (
        <Col className="form-field-view text-primary d-flex flex-wrap fs-12">
          <RadioView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.radio_with_details.label:
      return (
        <Col>
          <RadioWithDetailsView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.checkbox_with_details.label:
      return (
        <Col xs={columnWidth || 9}>
          <CheckboxWithDetailsView formField={formField} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.string.label:
      return (
        <Col xs={columnWidth || 9} className="form-field-view text-primary d-flex fs-12">
          <StringView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.text.label:
      return (
        <Col xs={columnWidth || 9} className="d-flex fs-12 form-field-view text-primary">
          <TextView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.number.label:
      return (
        <Col xs={columnWidth || 9} className="d-flex fs-12 form-field-view text-primary">
          <NumberView value={value} formField={formField} formFields={formFields} />
        </Col>
      );

    case FORM_FIELD_TYPES.time.label:
      return (
        <Col xs={columnWidth || 3} className="d-flex fs-12 form-field-view text-primary">
          <TimeView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.vessels_dropdown.label:
      return (
        <Col xs={columnWidth || 9} className="fs-12 form-field-view text-primary">
          <VesselsView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.text_with_editor.label:
      return (
        <Col xs={columnWidth || 9} className="fs-12 form-field-view text-primary">
          <TextEditorView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.form_instructions_image.label:
      return (
        <Col xs={columnWidth || 12} className="form-field-view d-flex justify-content-start">
          <FormInstructionsImageView formFieldParams={getFormFieldParams(formField)} />
        </Col>
      );

    case FORM_FIELD_TYPES.title.label:
    case FORM_FIELD_TYPES.subtitle.label:
      return (
        <Title
          value={getFormFieldParamsText(formField)}
          isSubTitle={FORM_FIELD_TYPES.subtitle.label === fieldTypeLabel}
          previewMode
        />
      );

    case FORM_FIELD_TYPES.instructions.label:
      return (
        <HTML
          title={getFieldName(formField)}
          value={getFormFieldParamsText(formField)}
          parentId={formFieldParentID}
          previewMode
        />
      );

    case FORM_FIELD_TYPES.table_with_fixed_rows.label:
      return (
        <Table
          formField={formField}
          formValues={formValues}
          fields={formFields}
          formPreviousValues={formPreviousValues}
          previewMode
        />
      );

    case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
      return (
        <Table
          formField={formField}
          formValues={formValues}
          formPreviousValues={formPreviousValues}
          fields={formFields}
          previewMode
          dynamic
        />
      );

    case FORM_FIELD_TYPES.office_user.label:
      return (
        <Col>
          <OfficeUserView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.manual_chapter.label:
      return (
        <Col>
          <ManualChapter formField={formField} />
        </Col>
      );

    case FORM_FIELD_TYPES.inventory_survey_rob.label:
      return (
        <Col>
          <InventorySurveyRobView formField={formField} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.dropdown_multiple_select.label:
      return (
        <Col xs={columnWidth || 6} className="form-field-view text-primary d-flex fs-12">
          <DropdownMultipleSelectView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.root_cause_analysis.label:
      return (
        <Col xs={columnWidth || 8}>
          <RootCauseAnalysisView value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.risk_assessment.label:
      return (
        <Col xs={12}>
          <RiskAssessmentView formField={formField} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.findings.label:
      return (
        <Col xs={12}>
          <FindingView formField={formField} value={value} />
        </Col>
      );

    case FORM_FIELD_TYPES.vessel_system_attribute.label:
      return (
        <Col xs={columnWidth || 8}>
          <VesselSystemAttributeView formField={formField} value={value} />
        </Col>
      );

    default:
      return null;
  }
};

export const renderViewString = (formField, value) => {
  const fieldTypeLabel = getFieldTypeLabel(formField);

  switch (fieldTypeLabel) {
    case FORM_FIELD_TYPES.drill_dropdown.label:
      return <DrillDropdownView value={value} />;

    case FORM_FIELD_TYPES.training_dropdown.label:
      return <TrainingDropdownView value={value} />;

    case FORM_FIELD_TYPES.string.label:
      return <StringView value={value} />;

    case FORM_FIELD_TYPES.title.label:
    case FORM_FIELD_TYPES.subtitle.label:
      return getFormFieldParamsText(formField);

    default:
      return null;
  }
};
