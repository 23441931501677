import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import SearchFormBody from './SearchFormBody';

import { useForm } from 'utils/hooks';
import config from './config';
import { updateOnboardSetupFilterPreset } from '@/api/setup-onboard/api.ts';

import { updateTableSearch } from 'store/tables/actions';
import { useAppDispatch } from '@/store/hooks';
import { setTableSearch } from '@/store/tables/actions-ts.ts';

const TableForm = ({ drawer, tableSearch, isSetupOnboard, label }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, collectValues, loadValues, isDirty, handleSubmitError } = useForm(config);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;
    const { shared_parties, shared_departments, table_label, id, ...rest } = values;

    try {
      setIsSubmitting(true);
      if (isSetupOnboard) {
        const res = await updateOnboardSetupFilterPreset({ ...rest, id, entity_type: label });
        dispatch(setTableSearch(res));
      } else {
        const shares = {
          parties: shared_parties.map(s => s.id),
          departments: shared_departments
        };

        await dispatch(
          updateTableSearch({
            id: tableSearch.id,
            table_label,
            shares,
            ...rest
          })
        );
      }
      setIsSubmitting(false);
      drawer.close();
    } catch (error) {
      setIsSubmitting(false);
      handleSubmitError(error);
    }
  };

  useEffect(() => {
    const params = isSetupOnboard
      ? { id: tableSearch.id }
      : {
          shared_parties: tableSearch.shares.parties,
          shared_departments: tableSearch.shares.departments.map(el => el.id)
        };

    loadValues({
      name: tableSearch.name,
      description: tableSearch.description,
      ...params
    });
  }, [isSetupOnboard, loadValues, tableSearch]);

  return (
    <Drawer {...drawer}>
      <DrawerHeader>Manage</DrawerHeader>

      <FormDrawer>
        <SearchFormBody isDirty={isDirty} formState={formState} isSetupOnboard={isSetupOnboard} />
      </FormDrawer>
      <FormFooter>
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={drawer.close}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting} color="primary" className="px-4">
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

export default TableForm;
