import DepartmentsFilterSelector from 'common/components/selectors/departments/FilterSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import VesselSelector from 'common/components/selectors/VesselSelector';
import PeriodicitySelector from 'common/components/selectors/PeriodicitySelector';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import { getUnitAndValueFromPeriodicity } from 'common/components/forms/planning-table/helpers';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';

import moment from 'moment';
import ImportanceSelector from 'common/components/selectors/ImportanceSelector';

export const mapFormPlanningSettingsRows = data => {
  return data && data.length
    ? data.map(row => ({
        form_id: _get(row, 'form.id'),
        form_uid: _get(row, 'form_uid'),
        form_uuid: _get(row, 'form_uuid'),
        form_form_field: _get(row, 'form_form_field'),
        form_form_field_uid: _get(row, 'form_form_field_uid'),
        form_form_field_value: _get(row, 'form_form_field_value'),
        form_planning_id: _get(row, 'form_planning_id', null),
        is_for_vessel: _get(row.form, 'is_for_vessel'),
        form_title: _get(row, 'form.name'),
        importance: _get(row, 'form.importance'),
        department: _get(row, 'form.department'),
        remarks: _get(row, 'remarks'),
        dates: _get(row, 'dates'),
        periodicity: _get(row, 'periodicity'),
        vessels: (_get(row, 'vessels') || []).map(vessel => _get(vessel, 'id', vessel)),
        vessels_with_plannings: (_get(row, 'vessels') || []).map(vessel => ({
          id: _get(vessel, 'id'),
          form_planning_id: _get(vessel, 'form_planning_id')
        }))
      }))
    : [];
};

export const getDatesPerMonth = dates => {
  return dates.reduce((acc, date) => {
    const month = moment(date.date).format('MMMM');
    if (!acc[month]) {
      acc[month.toLowerCase()] = [];
    }

    acc[month.toLowerCase()].push(moment(date.date, 'YYYY-MM-DD'));

    return acc;
  }, {});
};

export const getFormPlanningMonthColumns = data => {
  const allMonths = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];

  const emptyMonthObject = allMonths.reduce((acc, month) => {
    acc[month] = [];
    return acc;
  }, {});

  const dates = data.reduce((acc, setting) => {
    const settingMonthDates = getDatesPerMonth(setting?.dates || []);
    allMonths.forEach(month => {
      acc[month] = [...acc[month], settingMonthDates[month]];
    });

    return acc;
  }, emptyMonthObject);

  return allMonths.map((month, index) => ({
    title: month.slice(0, 3),
    label: month,
    dates: dates[month],
    id: index
  }));
};

export const getMonthlyDatesFromInitialDateAndPeriodicity = (initialDate, periodicity) => {
  const { unit, amount } = getUnitAndValueFromPeriodicity(periodicity);
  let currentDate = moment(initialDate).add(amount, unit);

  const dates = [initialDate];

  while (currentDate.isSameOrBefore(moment(initialDate).endOf('month'))) {
    dates.push(currentDate);
    currentDate = moment(currentDate).add(amount, unit);
  }
};

export const getFutureDateFromPeriodicityAndMultiplier = (startDate, periodicity, multiplier) => {
  const { amount, unit } = getUnitAndValueFromPeriodicity(periodicity);
  return moment(startDate).add(amount * multiplier, unit);
};

export const getPastDateFromPeriodicityAndMultiplier = (startDate, periodicity, multiplier) => {
  const { amount, unit } = getUnitAndValueFromPeriodicity(periodicity);
  return moment(startDate).subtract(amount * multiplier, unit);
};

export const generateDatesForYear = (initialDate, periodicity) => {
  if (!periodicity) {
    return [];
  }

  const start = moment(initialDate).startOf('year').startOf('day');
  const end = moment(initialDate).endOf('year').endOf('day');

  const dates = [];

  let counter = 0;

  // Future dates
  while (
    getFutureDateFromPeriodicityAndMultiplier(initialDate, periodicity, counter).isSameOrBefore(end)
  ) {
    dates.push(getFutureDateFromPeriodicityAndMultiplier(initialDate, periodicity, counter));
    counter++;
  }

  counter = 1;

  // Past dates
  while (
    getPastDateFromPeriodicityAndMultiplier(initialDate, periodicity, counter).isSameOrAfter(start)
  ) {
    dates.push(getPastDateFromPeriodicityAndMultiplier(initialDate, periodicity, counter));
    counter++;
  }

  return dates;
};

export const getField = (row, formState, field) => {
  return formState ? _get(formState, `state.${field}.value`) : _get(row, field);
};

export const formatFormPlanningSetting = (setting, row) => {
  const { form_uid, form_form_field_uid, form_form_field_value } = setting;

  return {
    form_uid,
    form_uuid: row?.form_uuid || undefined,
    form_form_field_uid,
    form_form_field_value: form_form_field_uid
      ? _get(form_form_field_value, 'id', form_form_field_value) || null
      : null,
    periodicity: setting.periodicity,
    remarks: setting.remarks,
    vessels:
      setting.vessels.length > 0
        ? setting.vessels
            .map(vessel => {
              const vessel_id = _isNumber(vessel) ? vessel : vessel?.id;
              return {
                id: vessel_id,
                form_planning_id: row?.vessels_with_plannings?.find(vp => vp.id === vessel_id)
                  ?.form_planning_id
              };
            })
            .filter(t => t.id)
        : undefined,
    dates:
      setting.dates?.map(date => (date instanceof moment ? date.format('YYYY-MM-DD') : date)) || [],
    form_planning_id: setting?.form_planning_id || undefined,
    is_for_vessel: setting.is_for_vessel
  };
};

export const generateYearOptions = () => {
  const currentYear = +moment().format('YYYY');
  const options = [];

  const FUTURE_YEARS = 5;
  const PAST_YEARS = 5;

  for (let i = currentYear + FUTURE_YEARS; i >= currentYear - PAST_YEARS; i--) {
    options.push({
      value: i,
      label: i
    });
  }
  return options;
};

export const constructFilters = filters => {
  const filterObject = {};

  Object.keys(filters).forEach(key => {
    const value = filters[key];
    if (!value || !value.length) return;

    filterObject[key] = value;
  });

  return filterObject;
};

export const constructURLParams = (filters, sorting) => {
  const params = {};
  const filtersObj = constructFilters(filters);

  params.filters = filtersObj;

  if (sorting) {
    params.sorting = sorting;
  }

  return params;
};

export const generateFormPlanningRowUID = setting => {
  let uid = setting.form_id;

  if (setting.form_form_field_uid) uid = `${uid}_${setting.form_form_field_uid}`;
  if (setting.form_form_field_value) uid = `${uid}_${setting.form_form_field_value}`;

  return { form_uuid: uid };
};

export const getFormPlanningRowUID = setting => {
  return setting?.form_uid;
};

export const tableColumns = [
  {
    key: 'importance',
    header: 'Importance',
    type: 'collection',
    canFilter: true,
    component: ImportanceSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'forms',
    header: 'Forms',
    type: 'collection',
    canFilter: true,
    component: FormsSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'departments',
    header: 'Departments',
    type: 'collection',
    canFilter: true,
    component: DepartmentsFilterSelector,
    componentRest: { isMulti: true, styled: false, gray: false }
  },
  {
    header: 'Vessels',
    key: 'vessels',
    type: 'collection',
    canFilter: true,
    component: VesselSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'periodicity',
    header: 'Periodicity',
    type: 'collection',
    canFilter: true,
    component: PeriodicitySelector,
    componentRest: { isMulti: true, styled: false }
  },
  {
    header: 'Remarks',
    key: 'remarks',
    type: 'collection',
    canFilter: false,
    component: CommentsPopover,
    componentRest: { isMulti: true }
  }
];
