import { CrewDocument, CrewDocumentySimilarityType } from '@/common/types/crew';
import { deleteRequest, get, post, put } from '@/utils/api';

export type GetCrewDocumentsSimilarityType = {
  id: number;
  issued_at?: string | null;
  crew_document_type_id?: number | null;
  issue_auths?: string[];
  grade?: string | null;
  periodicity?: string | null;
  code?: string | null;
  expires_at?: string | null;
  attachment_ids?: number[];
  country_id?: number | null;
  category?: string | null;
  comments?: string | null;
  is_archived?: boolean;
  number_of_doses?: number | null;
  manufacturer?: string | null;
  paper_agent?: string | null;
  crew_rank_id?: number | null;
};
export const getCrewDocumentsSimilarity = async (params: GetCrewDocumentsSimilarityType) => {
  const { id, ...rest } = params;
  const response = await get<CrewDocumentySimilarityType[]>(
    `/crew/${id}/documents/similarity`,
    rest
  );

  return response.data;
};

export type CreateCrewDocumentParams = {
  code: string | null;
  issued_at: string;
  issue_auths: string[];
  periodicity: string | null;
  grade: string | null;
  expires_at: string | null;
  comments: string | null;
  paper_agent: string | null;
  crew_document_type_id: number;
  country_id: number | null;
  crew_rank_id: number | null;
  attachment_ids: (string | number)[];
  number_of_doses?: number | null;
  manufacturer?: string | null;
};

export const createCrewDocument = async (id: number, params: CreateCrewDocumentParams) => {
  const response = await post<CrewDocument>(`/crew/${id}/documents`, params);

  return response.data;
};

export const updateCrewDocument = async (
  params: CreateCrewDocumentParams & { id: string | number }
) => {
  const { id, ...rest } = params;

  const response = await put<CrewDocument>(`/crew-documents/${id}`, rest);

  return response.data;
};

export const renewCrewDocument = async (
  params: CreateCrewDocumentParams & { id: string | number }
) => {
  const { id, ...rest } = params;

  const response = await put<CrewDocument>(`/crew-documents/${id}/renew`, rest);

  return response.data;
};

export const getCrewDocument = async (id: string | number) => {
  const response = await get<CrewDocument>(`/crew-documents/${id}`);

  return response.data;
};

export const archiveCrewDocument = async (id: string | number) => {
  const response = await put<CrewDocument>(`/crew-documents/${id}/archive`);

  return response.data;
};

export const unarchiveCrewDocument = async (id: string | number) => {
  const response = await put<CrewDocument>(`/crew-documents/${id}/unarchive`);

  return response.data;
};

export const deleteArchivedCrewDocument = async (id: string | number) => {
  const response = await deleteRequest<CrewDocument>(`/crew-documents/${id}`);

  return response.data;
};
