import { FC, MouseEvent, PropsWithChildren } from 'react';
import SvgRender from '@/ts-common/components/general/SvgRender';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import _get from 'lodash/get';
import useSvgIcon from '@/ts-common/utils/hooks/useSvgIcon';

type Type = string;

type CircledButtonProps = PropsWithChildren<{
  type?: Type;
  label?: string;
  size?: number;
  icon?: string; // import custom icon
  backgroundColor?: string;
  svgColor?: string;
  svgStyle?: {
    width: number;
    height: number;
  };
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
}>;

type IconProps = {
  type?: Type;
  size: number;
  icon?: string;
  backgroundColor?: string;
  svgColor?: string;
  svgStyle: {
    width: number;
    height: number;
  };
};

const defaultTypeStyles = {
  add: { background: 'yellow', color: 'primary' },
  edit: { background: 'yellow', color: 'primary' },
  remove: { background: 'red', color: 'white' },
  send: { background: 'primary', color: 'white' },
  close: { background: 'white', color: 'primary' },
  view: { background: 'white', color: 'primary' },
  archive: { background: 'white', color: 'primary' },
  unarchive: { background: 'white', color: 'primary' }
};

const Icon: FC<IconProps> = ({ type, icon, size, svgStyle, backgroundColor, svgColor }) => {
  const svgUrl = useSvgIcon(type, icon);

  const background = backgroundColor || _get(defaultTypeStyles, `${type}.background`, 'white');
  const color = svgColor || _get(defaultTypeStyles, `${type}.color`, 'white');

  return svgUrl ? (
    <div
      className={`d-flex align-items-center justify-content-center circled-icon bg-${background}`}
      style={{ minWidth: size, maxWidth: size, height: size }}
    >
      <SvgRender src={svgUrl} style={svgStyle} className={`text-${color}`} />
    </div>
  ) : null;
};

const CircledButton: FC<CircledButtonProps> = ({
  type,
  size = 20,
  icon,
  label,
  svgStyle = { width: 12, height: 12 },
  backgroundColor,
  svgColor,
  disabled,
  onClick,
  className = '',
  labelClassName = '',
  ...rest
}) => {
  return (
    <StyledButton
      className={`${disabled ? `disabled pe-none` : ''} ${className}`}
      disabled={disabled}
      onClick={disabled ? () => null : onClick}
      {...rest}
    >
      <Icon
        type={type}
        size={size}
        icon={icon}
        backgroundColor={backgroundColor}
        svgStyle={svgStyle}
        svgColor={svgColor}
      />
      {label ? <Label className={labelClassName}>{label}</Label> : null}
    </StyledButton>
  );
};

export default CircledButton;

const StyledButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }: { disabled?: boolean }) => (disabled ? 'none' : 'pointer')};
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.5 : 1)};
  transition: all 100ms ease-in-out;

  .circled-icon {
    background: white;
    border-radius: 100%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
    transition: all 100ms ease-in-out;

    &:active {
      box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &:hover:not(.disabled) {
    .circled-icon {
      filter: brightness(80%);
    }
  }
`;

const Label = styled.div`
  color: ${variables.primary};
  font-weight: bold;
  font-size: ${variables.size12};
  padding-left: ${variables.size8};
`;
