import { useState, useCallback, useMemo } from 'react';
import _update from 'lodash/update';
import _isEmpty from 'lodash/isEmpty';

import {
  createInitialState,
  collectValues,
  stateHasErrors,
  setValidationErrors,
  hasMissingRequiredFields,
  validateRequiredFields
} from './helpers';
import { clearStateValidationErrors } from './helpers-ts';

import debounce from 'lodash/debounce';
import _isArray from 'lodash/isArray';
import deepEqual, { deepEqualMutatedArrayFields } from 'common/utils/deep-compare';
import arrayMove from 'array-move';

export const useForm = (config, initialValues) => {
  const initialState = useMemo(() => createInitialState(config, initialValues), []);
  const [state, setFormState] = useState(initialState);
  const [loadedInitialState, setLoadedInitialState] = useState(initialState);
  const [isDirty, setIsDirty] = useState(false);

  const setField = useCallback((fieldName, transformContent, isArray) => {
    if (isArray) {
      setFormState(formState => {
        return {
          ...formState,
          [fieldName]: transformContent(formState[fieldName])
        };
      });
    } else {
      setFormState(formState => {
        return _update({ ...formState }, fieldName, n => transformContent(n));
      });
    }
  }, []);

  const collectMutatedArrayValues = useCallback(
    (excludeKeys = [], shouldReturnIndexRowData) => {
      const useLoadValues = collectValues(loadedInitialState, config, loadedInitialState);
      const mutatedValues = collectMutatedValues();

      return deepEqualMutatedArrayFields(
        useLoadValues,
        mutatedValues,
        excludeKeys,
        shouldReturnIndexRowData
      );
    },
    [state, loadedInitialState]
  );

  const collectMutatedValues = useCallback(() => {
    const newValues = collect();
    const useLoadValues = collectValues(loadedInitialState, config, loadedInitialState);

    if (_isEmpty(newValues)) return null;

    return deepEqual(useLoadValues, newValues, true);
  }, [state, loadedInitialState]);

  // re-initialize (like in Formik)
  const loadValues = useCallback(values => {
    const initialState = createInitialState(config, values, values);
    setLoadedInitialState(initialState);

    setFormState(initialState);
    setIsDirty(false);
  }, []);

  const collect = useCallback(
    (validateBeforeSubmittion = true) => {
      if (validateBeforeSubmittion && hasMissingRequiredFields(state, config) === true) {
        validateRequiredFields(setFormState, config);
        return null;
      } else {
        setIsDirty(false);
        return collectValues(state, config, state);
      }
    },
    [state]
  );
  const hasErrors = useMemo(() => stateHasErrors(state), [state]);

  const handleSubmitError = useCallback(error => {
    if (error.status === 422) {
      setValidationErrors(error.data.errors, setFormState);
    }

    setIsDirty(true);
  }, []);

  const clearValidationErrors = useCallback(() => {
    const updatedState = clearStateValidationErrors(state);

    setFormState(updatedState);

    setIsDirty(false);
  }, [state]);

  const resetForm = useCallback(() => {
    setFormState(initialState);
  }, []);

  return {
    formState: { state, config, setField, isDirty, setIsDirty },
    collectValues: collect,
    collectMutatedValues,
    collectMutatedArrayValues,
    loadValues,
    hasErrors,
    isDirty,
    handleSubmitError,
    resetForm,
    clearValidationErrors
  };
};

export const useFormState = form => {
  const { setField, isDirty, setIsDirty, config } = form;

  const setFieldValue = useCallback(
    (fieldName, value) => {
      setField(fieldName, field => ({ value: value, error: field.error }));
    },
    [setField]
  );

  const setFieldError = useCallback(
    (fieldName, error) => setField(fieldName, field => ({ value: field.value, error: error })),
    [setField]
  );

  const validateField = useCallback(
    fieldName =>
      debounce(value => {
        const { validation, transformOnSubmit } = config[fieldName];
        if (!validation) {
          setFieldError(fieldName, null);
          return;
        }

        validation
          .validate(transformOnSubmit ? transformOnSubmit(value) : value)
          .then(() => {
            setFieldError(fieldName, null);
          })
          .catch(err => {
            setFieldError(fieldName, err.message);
          });
      }, 300),
    [config, setFieldError]
  );

  const changeFieldValue = useCallback(
    (fieldName, value, affectsDirtyStatus = true) => {
      validateField(fieldName)(value);
      setFieldValue(fieldName, value);
      setIsDirty(prev => (!prev && affectsDirtyStatus ? true : prev));
    },
    [setIsDirty, validateField, setFieldValue]
  );

  const changeField = useCallback(
    fieldName => (e, changeStatus) => changeFieldValue(fieldName, e.target.value, changeStatus),
    [changeFieldValue]
  );
  const selectField = useCallback(
    fieldName => (option, changeStatus) => changeFieldValue(fieldName, option, changeStatus),
    [changeFieldValue]
  );

  const addSubform = useCallback(
    (arrayName, initialState = {}) => {
      const newSubformStates = _isArray(initialState)
        ? initialState.map(iniState => createInitialState(config[arrayName], iniState))
        : [createInitialState(config[arrayName], initialState)];

      setField(arrayName, array => [...array, ...newSubformStates], true);
      if (!isDirty) setIsDirty(true);
    },
    [setField, config, isDirty, setIsDirty]
  );

  const removeSubform = useCallback(
    (arrayName, index) => {
      setField(arrayName, array => array.filter((_, i) => i !== index), true);
      if (!isDirty) setIsDirty(true);
    },
    [setField, isDirty, setIsDirty]
  );

  const removeEverySubform = useCallback(
    (arrayName, exceptIndexes = []) => {
      setField(arrayName, array => array.filter((_, i) => exceptIndexes.includes(i)), true);
      if (!isDirty) setIsDirty(true);
    },
    [setField, isDirty, setIsDirty]
  );

  const rearrangeSubform = useCallback(
    (arrayName, oldIndex, newIndex) => {
      setField(arrayName, array => arrayMove(array, oldIndex, newIndex), true);
    },
    [setField]
  );

  const setSubStates = useCallback(
    (arrayName, newArrayState) => {
      setField(arrayName, () => newArrayState.map(s => s.state), true);
    },
    [setField]
  );

  const setArrayField = (arrayName, index) => {
    return (fieldName, transformContent) =>
      setField(
        arrayName,
        array =>
          array.map((fields, i) =>
            i === index
              ? {
                  ...fields,
                  [fieldName]: transformContent(fields[fieldName])
                }
              : fields
          ),
        true
      );
  };

  const subState = (arrayName, index) => ({
    index: index,
    state: form.state[arrayName][index],
    setField: setArrayField(arrayName, index),
    config: config[arrayName],
    isDirty: isDirty,
    setIsDirty: setIsDirty,
    remove: () => removeSubform(arrayName, index),
    reorder: (oldIndex, newIndex) => rearrangeSubform(arrayName, oldIndex, newIndex)
  });

  const subStates = arrayName =>
    form.state[arrayName].map((_, index) => subState(arrayName, index));

  const setFieldErrorWithDirty = useCallback(
    (fieldName, error, affectsDirtyStatus = true) => {
      setFieldError(fieldName, error);
      if (!isDirty && affectsDirtyStatus) setIsDirty(true);
    },
    [isDirty, setIsDirty, setFieldError]
  );

  return {
    fields: form.state,
    removeSelf: form.remove,
    changeField,
    selectField,
    addSubform,
    removeSubform,
    removeEverySubform,
    rearrangeSubform,
    setFieldError: setFieldErrorWithDirty,
    subState,
    subStates,
    setFieldValue,
    setSubStates
  };
};
