import { ButtonGroup, Button } from 'reactstrap';
import { FC, ReactNode } from 'react';
import Tooltip from '@/ts-common/components/general/Tooltip';
import SvgRender from '@/ts-common/components/general/SvgRender';
import AuthCheck from 'components/permissions/AuthCheck';

import { NavLink, NavLinkProps } from 'react-router-dom';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type TabContentProps = { icon?: string; label?: ReactNode; iconStyle?: React.CSSProperties };

const TabContent: FC<TabContentProps> = ({ icon, label, iconStyle }) => {
  if (icon) return <SvgRender src={icon} style={iconStyle || { width: 16, height: 16 }} />;

  return label;
};

export type NavigationGroupTab = {
  label?: string | ReactNode;
  icon?: string;
  iconStyle?: React.CSSProperties;
  tooltip?: string;
  warning?: ReactNode;
  permissions?: string[];
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
} & (
  | {
      isActive: NavLinkProps['isActive'];
      to: NavLinkProps['to'];
    }
  | { isActive: boolean; to?: undefined }
);

type TabProps = {
  size?: 'sm' | 'lg' | 'md';
} & NavigationGroupTab;

const Tab: FC<TabProps> = ({
  label,
  icon,
  tooltip,
  warning,
  permissions,
  to,
  isActive,
  className = '',
  size = 'lg',
  iconStyle,
  isDisabled,
  ...rest
}) => {
  const { avoidRender, tooltipID } = useTooltipID('nav-tab');

  return (
    <AuthCheck permissions={permissions}>
      {to ? (
        <NavLink
          exact
          id={tooltipID}
          isActive={isActive}
          to={to}
          className={`btn-group-button btn-${size} ${className} ${
            isDisabled ? 'pe-none opacity-5' : ''
          }`}
          {...rest}
        >
          <TabContent icon={icon} iconStyle={iconStyle} label={label} />
        </NavLink>
      ) : (
        <Button
          id={tooltipID}
          color={'group-button'}
          size={size}
          className={`${isActive ? 'active' : ''} ${className} ${
            isDisabled ? 'pe-none opacity-5' : ''
          }`}
          {...rest}
        >
          <TabContent icon={icon} iconStyle={iconStyle} label={label} />
        </Button>
      )}

      {tooltip && tooltipID && !avoidRender ? (
        <Tooltip fade={false} target={tooltipID} placement="bottom">
          {tooltip}
        </Tooltip>
      ) : null}
      {warning ? (
        <>
          <div
            id={tooltipID || ''}
            className="btn-group-button--warning position-absolute bg-red"
          />
          {tooltipID && !avoidRender ? (
            <Tooltip placement="right" innerClassName="min-width-fit" target={tooltipID}>
              {warning}
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </AuthCheck>
  );
};

type NavigationGroupTabsProps = {
  tabs: NavigationGroupTab[];
  className?: string;
  size?: 'sm' | 'lg' | 'md';
};

const NavigationGroupTabs: FC<NavigationGroupTabsProps> = ({
  tabs = [],
  className = '',
  size = 'lg'
}) => {
  return (
    <ButtonGroup className={`d-inline-flex g-0 group-button ${className}`}>
      {tabs.map((tab, i) => (
        <Tab size={size} key={i} {...tab} />
      ))}
    </ButtonGroup>
  );
};

export default NavigationGroupTabs;
