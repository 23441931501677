import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import { get, post, put, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyPartyEmployees } from '@/api/parties/api';

// GET REQUESTS

export const getCompany = params => dispatch => {
  dispatch({ type: TYPES.GET_COMPANY.START, payload: { params } });

  return get(`/parties/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_COMPANY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_COMPANY.ERROR, payload: error });
      throw error;
    });
};

export const updateCompany = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_COMPANY.START, payload: { params } });

  return put(`/parties/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_COMPANY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_COMPANY.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const getConnectedCompanies = params => dispatch => {
  const { id: companyId, table, ...rest } = params;

  dispatch({ type: TYPES.GET_CONNECTED_COMPANIES.START, payload: { params } });
  if (table) {
    dispatch({
      type: TABLE_TYPES.GET_TABLE_LIST.START,
      payload: { params: rest, table }
    });
  }

  return get(`/companies/${companyId}/connected-companies`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_CONNECTED_COMPANIES.SUCCESS, payload: response.data });
      if (table) {
        dispatch({
          type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
          payload: { data: response.data, table }
        });
      }

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CONNECTED_COMPANIES.ERROR, payload: error });
      if (table) {
        dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      }
      throw error;
    });
};

export const getAgentHires = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_AGENT_HIRES.START });

  return get(`/company-profile/${id}/agent-hires`)
    .then(response => {
      dispatch({ type: TYPES.GET_AGENT_HIRES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_AGENT_HIRES.ERROR, payload: error }));
};

export const getCompanyEmployeesAction = params => dispatch => {
  dispatch({ type: TYPES.GET_COMPANY_EMPLOYEES.START });

  return getCompanyPartyEmployees(params)
    .then(response => {
      dispatch({ type: TYPES.GET_COMPANY_EMPLOYEES.SUCCESS, payload: response });
      return response;
    })
    .catch(error => dispatch({ type: TYPES.GET_COMPANY_EMPLOYEES.ERROR, payload: error }));
};

// POST REQUESTS

export const createAgentHire = params => dispatch => {
  dispatch({ type: TYPES.CREATE_AGENT_HIRE.START });

  return post(`/agent-manual-timeline`, { ...params })
    .then(response => {
      dispatch({
        type: TYPES.CREATE_AGENT_HIRE.SUCCESS,
        payload: { ...response.data, type: 'manual' }
      });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.CREATE_AGENT_HIRE.ERROR, payload: error }));
};

export const createConnectedCompanies = params => dispatch => {
  const { companyId, ...rest } = params;
  dispatch({ type: TYPES.CREATE_CONNECTED_COMPANIES.START });

  return post(`companies/${companyId}/connected-companies`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_CONNECTED_COMPANIES.SUCCESS,
        payload: response.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_CONNECTED_COMPANIES.ERROR, payload: error });
      throw error;
    });
};

export const createCompanyType = params => dispatch => {
  dispatch({ type: TYPES.CREATE_COMPANY_TYPE.START });
  return post('/companies/types', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_COMPANY_TYPE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.CREATE_COMPANY_TYPE.ERROR, payload: error }));
};

// PUT REQUESTS

export const updateAgentHire = params => dispatch => {
  const { id, type, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_AGENT_HIRE.START });

  return put(`/agent-manual-timeline/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_AGENT_HIRE.SUCCESS, payload: { ...response.data, type } });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_AGENT_HIRE.ERROR, payload: error }));
};

export const updateCompanyType = params => dispatch => {
  const { id, ...rest } = params;
  const table = 'company_types';
  dispatch({ type: TYPES.UPDATE_COMPANY_TYPE.START });
  dispatch(updateTableRow({ data: params, table }));

  return put(`/companies/types/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_COMPANY_TYPE.SUCCESS, payload: response.data });
      dispatch(updateTableRow({ data: response.data, table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_COMPANY_TYPE.ERROR, payload: error });
      dispatch(resetTableRowUpdate({ data: params, table }));
    });
};

// DELETE REQUESTS

export const deleteAgentHire = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.DELETE_AGENT_HIRE.START });

  return deleteRequest(`/agent-manual-timeline/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_AGENT_HIRE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_AGENT_HIRE.ERROR, payload: error }));
};

export const deleteCompanyType = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.DELETE_COMPANY_TYPE.START });

  return deleteRequest(`/companies/types/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_COMPANY_TYPE.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_COMPANY_TYPE.ERROR, payload: error }));
};

export const resetProfile = () => dispatch => {
  return dispatch({ type: TYPES.RESET_PROFILE });
};

// Bank Accounts
export const getCompanyBankAccounts = createAsyncThunk(
  'GET_COMPANY_BANK_ACCOUNTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { table, id, ...rest } = params;
    dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

    try {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });
      const response = await get(`/parties/${params.id}/bank-accounts`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });

      return response.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      return rejectWithValue(error);
    }
  }
);

export const createCompanyBankAccount = createAsyncThunk(
  'CREATE_COMPANY_BANK_ACCOUNT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await post(`/parties/${params.id}/bank-accounts`, params);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editCompanyBankAccount = createAsyncThunk(
  'EDIT_COMPANY_BANK_ACCOUNT',
  async ({ id, account_id, table, updateStateWith, ...rest }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/parties/${id}/bank-accounts/${account_id}`, rest);

      dispatch(updateTableRow({ data: { ...res.data, ...updateStateWith }, table: table }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteCompanyBankAccount = createAsyncThunk(
  'DELETE_COMPANY_BANK_ACCOUNT',
  async ({ id, account_id, ...rest }, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteRequest(`/parties/${id}/bank-accounts/${account_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
// --

// Accounting

export const getCompanyAccount =
  (params = {}) =>
  dispatch => {
    const { id } = params;

    dispatch({ type: TYPES.GET_COMPANY_ACCOUNT.START, payload: { params } });

    return get(`/accounts/${id}`)
      .then(response => {
        dispatch({
          type: TYPES.GET_COMPANY_ACCOUNT.SUCCESS,
          payload: { data: response.data, params }
        });

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_COMPANY_ACCOUNT.ERROR, payload: error });

        throw error;
      });
  };

export const updateCompanyAccount = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_COMPANY_ACCOUNT.START, payload: { params } });

  return put(`/accounts/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_COMPANY_ACCOUNT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_COMPANY_ACCOUNT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};
