import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectFieldValues } from './selectors';
import { getPartiesSimilarity, GetPartiesSimilarityType } from '@/api/parties/api';
import { RootState } from '@/store';
import {
  getCrewDocumentsSimilarity,
  GetCrewDocumentsSimilarityType
} from '@/api/crew-documents/api';

export const getPartiesSimilarityAction = createAsyncThunk(
  'GET_PARTIES_SIMILARITY',
  async (_, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const fieldValues = selectFieldValues(state);

      if (!fieldValues || (fieldValues.type !== 'person' && fieldValues.type !== 'company')) return;

      const parsedParams: GetPartiesSimilarityType = {
        type: fieldValues.type,
        first_name: fieldValues.first_name,
        middle_name: fieldValues.middle_name,
        last_name: fieldValues.last_name,
        company_name: fieldValues.company_name,
        phone: fieldValues.phone,
        email: fieldValues.email
      };

      const res = await getPartiesSimilarity(parsedParams);

      return fulfillWithValue({ data: res, params: fieldValues });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCrewDocumentsSimilarityAction = createAsyncThunk(
  'GET_CREW_DOCUMENTS_SIMILARITY',
  async (params: GetCrewDocumentsSimilarityType, { rejectWithValue }) => {
    try {
      const res = await getCrewDocumentsSimilarity(params);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
