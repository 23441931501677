import { getLetterFromNumber } from 'common/utils/texts';
import _orderBy from 'lodash/orderBy';

export const parseReviewProcessStepsToLogs = steps => {
  const logs = steps.reduce((prev, step, index) => {
    const logs = [];

    const hasManySubsteps = step.substeps.length > 1;

    step.substeps.forEach((substep, i) => {
      const step_number = index + 1;
      const substep_letter = hasManySubsteps ? getLetterFromNumber(i + 1) : null;

      const departmentIds = substep.departments.map(department => department.id);
      const departmentRoleIds = substep.department_roles.map(departmentRole => departmentRole.id);

      substep.history_logs.forEach(history => {
        if (!history?.performed_by) {
          logs.push({
            ...history,
            step_number,
            substep_letter,
            user_departments: [],
            user_department_roles: []
          });

          return null;
        }

        // Substep approvals are based on either roles or departments if it's roles we get the roles from he substep and the departments of the role
        // if it's departments we get the departments from the substep and the roles from the department in order to render the user role and departments
        const userDepartmentsBasedOnSubsteps = history.performed_by.departments.filter(department =>
          departmentIds.includes(department.id)
        );

        const userDepartmentRolesBasedOnSubsteps = history.performed_by.department_roles.filter(
          departmentRole => departmentRoleIds.includes(departmentRole.id)
        );

        const userDepartmentRolesBasedOnDepartments = history.performed_by.department_roles.filter(
          departmentRole => departmentIds.includes(departmentRole.pivot.department_id)
        );

        const userDepartmentsBasedOnRoles = history.performed_by.departments.filter(department =>
          userDepartmentRolesBasedOnSubsteps.some(
            departmentRole => departmentRole.pivot.department_id === department.id
          )
        );

        logs.push({
          ...history,
          step_number,
          substep_letter,
          user_departments: userDepartmentsBasedOnSubsteps.length
            ? userDepartmentsBasedOnSubsteps
            : userDepartmentsBasedOnRoles,
          user_department_roles: userDepartmentRolesBasedOnSubsteps?.length
            ? userDepartmentRolesBasedOnSubsteps
            : userDepartmentRolesBasedOnDepartments
        });
      });
    });
    return [...prev, ...logs];
  }, []);

  return _orderBy(logs, ['performed_at'], ['desc']);
};

export const logColorClassName = action => {
  switch (action) {
    case 'completed':
      return 'text-turquoise';
    case 'revoked':
      return 'text-primary';
    case 'rejected':
      return 'text-red';
    default:
      return;
  }
};

export const logText = action => {
  switch (action) {
    case 'completed':
      return 'Approved';
    case 'revoked':
      return 'Revoked';
    case 'rejected':
      return 'Rejected';
    default:
      return;
  }
};
