import { FC, useEffect, useMemo, useState } from 'react';
import NavigationArrows from 'common/components/general/NavigationArrows';
import useRouter from 'use-react-router';
import paths from '@/routing/routes/_paths';
import { stringifyObj, parseQueryParams } from 'utils/urls';
import queryClient from '@/utils/query';
import { TABLE_QUERY_KEY } from '@webthatmatters/orca-table';
import { FORM_SUBMISSIONS_TABLE_KEY } from '@/api/form-submissions/query-keys';
import { QueryKey } from '@tanstack/react-query';
import { FormSubmissionBase } from '@/common/types/form-submissions';

type QueryCacheType =
  | [
      QueryKey,
      (
        | {
            data: FormSubmissionBase[];
          }
        | undefined
      )
    ]
  | undefined;

interface GetFormParams {
  id: number;
  isSubmission: boolean;
}

interface NavigationButtonsProps {
  formSubmissionId: number;
  getForm: ({ id, isSubmission }: GetFormParams) => Promise<any> | void;
}

const NavigationButtons: FC<NavigationButtonsProps> = ({ formSubmissionId, getForm }) => {
  const [previous, setPrevious] = useState<number | null>(null);
  const [next, setNext] = useState<number | null>(null);

  const { history } = useRouter();

  const [query] = queryClient.getQueriesData<{ data: FormSubmissionBase[] }>({
    queryKey: [TABLE_QUERY_KEY, FORM_SUBMISSIONS_TABLE_KEY],
    type: 'active'
  });
  const tableDataIds = useMemo(
    () => ((query as QueryCacheType)?.[1]?.data || []).map(({ id }) => id),
    [query]
  );

  const onArrowClick = async (formId: number) => {
    const params = parseQueryParams(history.location.search);

    history.replace({
      pathname: `${paths.forms}/${formId}`,
      search: `${stringifyObj(params)}`
    });

    await getForm({ id: formId, isSubmission: true });
  };

  useEffect(() => {
    const formSubmissionIndex = tableDataIds?.findIndex(
      (field: number) => field === formSubmissionId
    );

    if (formSubmissionIndex !== -1) {
      setNext(tableDataIds?.[formSubmissionIndex + 1]);
      setPrevious(tableDataIds?.[formSubmissionIndex - 1]);
    } else {
      setNext(null);
      setPrevious(null);
    }
  }, [formSubmissionId, tableDataIds.length, tableDataIds]);

  return (
    <NavigationArrows
      disabledNext={!next}
      disabledPrevious={!previous}
      onNextClick={() => next && onArrowClick(next)}
      onPreviousClick={() => previous && onArrowClick(previous)}
      className={'m-0 px-2'}
    />
  );
};

export default NavigationButtons;
