import { useEffect, useState } from 'react';
import { Row, Col, Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { selectListIsFetching, selectListOptionsFromStore } from 'store/lists/selectors';

import send from 'common/assets/svg/actions/send.svg';
import PageSaving from 'common/components/general/PageSaving';
import PreventActionButton from 'common/components/buttons/PreventActionButton';
import SaveTemplateModal from './SaveTemplateModal';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import {
  isPurchasingCommunicationDrawerOpen,
  isRevisedQuantityEmpty,
  selectActiveRequisitionID
} from 'common/components/purchasing/requisition/store/selectors';
import {
  sendPurchasingRequisitionEmails,
  updatePurchasingRequisitionEmailTemplates,
  getPurchasingRequisitionSuppliers,
  getPurchasingRequisitionItems
} from 'common/components/purchasing/requisition/store/actions';
import { togglePurchasingCommunicationsDrawer } from 'common/components/purchasing/requisition/store/actions';
import { useAppSelector } from '@/store/hooks';
import { selectAccountExternalEmail } from '@/store/account/selectors-ts.ts';
import { selectShouldIncludeDefaultEmailAttachments } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';

const Actions = ({ collectValues, formState, resetForm, hasErrors }) => {
  const isOpen = useSelector(isPurchasingCommunicationDrawerOpen);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dispatch = useDispatch();
  const { fields, selectField } = useFormState(formState);
  const externalEmail = useAppSelector(selectAccountExternalEmail);
  const shouldIncludeDefaultEmailAttachments = useAppSelector(
    selectShouldIncludeDefaultEmailAttachments
  );

  const requisitionId = useSelector(selectActiveRequisitionID);
  const [sendEmailModalIsOpen, setSendEmailModalIsOpen] = useState(false);
  const [saveTemplateModalIsOpen, setSaveTemplateModalIsOpen] = useState(false);
  const [canSaveTemplate, setCanSaveTemplate] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const emailTemplates = useSelector(state => selectListOptionsFromStore(state, 'email-templates'));
  const isReviseQuanityEmpty = useSelector(isRevisedQuantityEmpty);
  const { avoidRender, tooltipID } = useTooltipID(`send-email`);

  const isFetchingEmailTemplates = useSelector(state =>
    selectListIsFetching(state, 'email-templates')
  );

  const updateTemplate = async () => {
    try {
      setIsUpdating(true);

      const { from, cc, bcc, body, to } = fields;

      const params = {
        email_template_id: fields.email_template_id.value,
        from: from.value,
        type: 'purchasing_requisition',
        bcc: bcc.value?.map(email => email?.supplier_email),
        cc: cc.value?.map(email => email?.supplier_email),
        body: body.value,
        to: to.value?.map(email => email?.supplier_email)
      };

      await dispatch(updatePurchasingRequisitionEmailTemplates(params));

      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      console.log(error);
    }
  };

  const sendEmail = async () => {
    try {
      const values = collectValues();

      if (!values) return;
      const {
        subject,
        from,
        to,
        cc,
        bcc,
        body,
        status_id,
        attachments,
        synced_office_attachments,
        synced_vessel_attachments
      } = values;

      const email = {
        subject,
        from,
        to: to?.map(email => email?.supplier_email),
        body,
        cc: cc?.map(email => email?.supplier_email),
        bcc: bcc?.length ? bcc.map(email => email?.supplier_email) : [],
        attachment_ids: [
          ...attachments,
          ...synced_office_attachments,
          ...synced_vessel_attachments
        ].map(a => a?.id)
      };

      await dispatch(
        sendPurchasingRequisitionEmails({
          id: requisitionId,
          default_attachments: shouldIncludeDefaultEmailAttachments,
          email,
          status_id
        })
      );

      await dispatch(getPurchasingRequisitionSuppliers({ id: requisitionId }));

      await dispatch(
        getPurchasingRequisitionItems({
          id: requisitionId
        })
      );

      resetForm();

      if (externalEmail) {
        selectField('from')(externalEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (fields.from.value?.length && fields.body.value?.length) {
      setCanSaveTemplate(true);
    } else if (canSaveTemplate) {
      setCanSaveTemplate(false);
    }
  }, [fields.from.value?.length, fields.body.value?.length, canSaveTemplate]);

  return (
    <Row className="pt-2 align-items-center">
      <Col>
        {!isFetchingEmailTemplates ? (
          <Dropdown
            direction="down"
            isOpen={dropdownOpened}
            toggle={() => {
              emailTemplates?.length
                ? setDropdownOpened(!dropdownOpened)
                : setSaveTemplateModalIsOpen(true);
            }}
            disabled={!canSaveTemplate}
          >
            <DropdownToggle
              type="button"
              color="link"
              className={`d-inline-flex align-items-center fw-bold px-2 py-1 me-1 border ${
                canSaveTemplate ? 'border-primary' : 'border-inactive'
              }`}
              disabled={!canSaveTemplate}
            >
              <strong
                className={`fs-10 lh-1 ps-1 ${canSaveTemplate ? 'text-primary' : 'text-inactive'}`}
              >
                {fields.email_template_id?.value ? 'SAVE TEMPLATE' : 'SAVE AS A NEW TEMPLATE'}
              </strong>
            </DropdownToggle>
            <DropdownMenu className="px-0 ms-2" end={true}>
              <DropdownItem className="pointer" onClick={() => setSaveTemplateModalIsOpen(true)}>
                Save as new template
              </DropdownItem>
              <DropdownItem
                className="pointer"
                onClick={updateTemplate}
                disabled={canSaveTemplate && fields?.email_template_id?.value ? false : true}
              >
                Update current template
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : null}
      </Col>
      <Col xs="auto" className="d-flex">
        <Button
          type="button"
          color="cancel"
          className="fs-10 px-0 py-1 me-4"
          onClick={() => dispatch(togglePurchasingCommunicationsDrawer(!isOpen))}
        >
          <strong>CANCEL</strong>
        </Button>

        <div id={tooltipID}>
          <PreventActionButton
            disabled={hasErrors || isReviseQuanityEmpty}
            buttonLabel="SEND EMAIL"
            onModalAccept={() => sendEmail()}
            onButtonClick={() => setSendEmailModalIsOpen(true)}
            closeModal={() => setSendEmailModalIsOpen(false)}
            isModalOpen={sendEmailModalIsOpen}
            buttonIcon={send}
            buttonClassName="fs-10"
            iconStyle={{ width: 12, height: 12 }}
            modalProps={{
              header: 'Send Email',
              body: `Are you sure you want to send this email? This cannot be undone.`,
              actionText: 'SEND',
              action: 'send',
              actionHoverColor: 'primary',
              container: '.purchasing-communications',
              className: 'send-email-modal',
              backdropClassName: 'blurred-backdrop'
            }}
          />
        </div>

        {avoidRender || !isReviseQuanityEmpty ? null : (
          <Tooltip fade={false} target={tooltipID} placement={'top'}>
            REV QTY must be filled in for all items in order to send an email.
          </Tooltip>
        )}

        <SaveTemplateModal
          isOpen={saveTemplateModalIsOpen}
          closeModal={() => setSaveTemplateModalIsOpen(false)}
          emailFields={fields}
        />
        <PageSaving isSaving={isUpdating} />
      </Col>
    </Row>
  );
};

export default Actions;
