import CircledButton from '@/ts-common/components/buttons/CircledButton';
import {
  isPurchasingItemsSelectionDrawerOpen,
  isPmsItemsSelectionDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import {
  selectItem,
  selectOnBoardStatus,
  selectRequisitionStatusLabel,
  selectStoreItemIsExtraCharge
} from '@/common/components/purchasing/requisition/store/selectors-ts';
import { isAuthorized } from '@/utils/permissions/authorize';
import { selectAccount } from '@/store/account/selectors-ts';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal.tsx';
import { useState } from 'react';
import {
  deletePurchasingRequisitionItem,
  togglePurchasingItemsSelectionDrawer,
  togglePmsItemsSelectionDrawer
} from 'common/components/purchasing/requisition/store/actions';
import doubleArrows from '@/common/assets/svg/actions/double-arrows.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import {
  setActiveCategoryId,
  setSelectedItemToBeReplaced
} from 'common/components/purchasing/requisition/store/actions';
import permissions from '@/common/utils/permissions/constants';
import ItemActionsInfo from './ItemActionsInfo';

interface ItemActionsProp {
  itemID: string;
  categoryID: number;
}

const ItemActions: React.FC<ItemActionsProp> = ({ itemID, categoryID }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);

  const isPurchasingItemsDrawerOpen = useAppSelector(isPurchasingItemsSelectionDrawerOpen);
  const isPmsItemsDrawerOpen = useAppSelector(isPmsItemsSelectionDrawerOpen);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const status = useAppSelector(selectRequisitionStatusLabel);
  const onBoardStatus = useAppSelector(selectOnBoardStatus);

  const isDisabled = isPurchasingItemsDrawerOpen || isPmsItemsDrawerOpen;

  const item = useAppSelector(state => selectItem(state, itemID));
  const isExtraChargesItem = useAppSelector(state => selectStoreItemIsExtraCharge(state, itemID));

  const showActionsBasedOnStatus = !isOnBoard
    ? onBoardStatus !== 'for_correction' && (status === 'rqn' || status === 'rfq')
    : false;
  const userCanReplaceItems = isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.replace
  ]);
  const userCanReplaceItemsAtAnyStatus = isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.replace_any_status
  ]);
  const userCanRemoveItems = isAuthorized(account, [
    permissions.office.purchasing.requisitions.items.remove
  ]);
  const replaceActionIsVisible = showActionsBasedOnStatus
    ? userCanReplaceItems || userCanReplaceItemsAtAnyStatus
    : onBoardStatus === 'submitted' && userCanReplaceItemsAtAnyStatus;
  const removeActionIsVisible = showActionsBasedOnStatus && userCanRemoveItems;

  const onReplace = () => {
    dispatch(setActiveCategoryId(categoryID));
    dispatch(setSelectedItemToBeReplaced(item?.requisition_item_id));

    if (item?.item_type === 'spare_part') {
      dispatch(togglePmsItemsSelectionDrawer(true));
    } else {
      dispatch(togglePurchasingItemsSelectionDrawer(true));
    }
  };

  const onRemove = async () => {
    await dispatch(deletePurchasingRequisitionItem({ id: item?.requisition_item_id }));
  };

  return isOnBoard ? null : (
    <>
      <div className="supplier-item-actions width-48 right-0">
        {isExtraChargesItem ? null : replaceActionIsVisible ? (
          <CircledButton
            icon={doubleArrows}
            onClick={onReplace}
            svgStyle={{ width: 12, height: 12 }}
            size={18}
            className="me-1"
            disabled={isDisabled}
            svgColor={'royal-blue'}
          ></CircledButton>
        ) : null}

        {removeActionIsVisible ? (
          <CircledButton
            icon={remove}
            onClick={() => setShowDeleteModal(true)}
            svgStyle={{ width: 9, height: 9 }}
            size={18}
            disabled={isDisabled}
            svgColor={'coral'}
          ></CircledButton>
        ) : null}

        <DangerousActionModal
          onAccept={onRemove}
          onCancel={() => setShowDeleteModal(false)}
          isOpen={showDeleteModal}
          acceptButtonText={'REMOVE'}
          cancelButtonText={'CANCEL'}
          header={'Remove Item'}
          icon={remove}
        >
          <div>
            Are you sure you want to remove the <br /> <strong>{item.description}</strong> ?
          </div>
        </DangerousActionModal>
      </div>
      {removeActionIsVisible || replaceActionIsVisible ? <ItemActionsInfo itemID={itemID} /> : null}
    </>
  );
};

export default ItemActions;
