import React, { useState, useEffect } from 'react';
import _remove from 'lodash/remove';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import { Button } from 'reactstrap';
import { stringifyObj, parseQueryParams } from 'utils/urls';
import { jobTableLabels } from 'common/utils/fixed';

import * as dashboardActions from 'store/dashboards/actions';
import * as sidebarActions from 'store/sidebar/actions';
import * as favoritesActions from 'store/favorites/actions';

import paths from 'routing/routes/_paths';
import Slider from 'react-slick';
import SearchHeader from './SearchHeader';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import { RenderItem, renderPagination } from '../helpers';

const slickSettings = {
  dots: false,
  infinite: false,
  speed: 400,
  centerMode: false,
  variableWidth: true,
  draggable: false,
  slidesToShow: 3,
  slidesToScroll: 3
};

const Search = () => {
  const { history, location } = useRouter();
  const [deleteItem, setDeleteItem] = useState(null);
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const [fetchDashboards] = useActions([dashboardActions.fetchDashboards]);
  const [getSavedItems, deleteSavedItem, getRecentItems] = useActions([
    sidebarActions.getSavedItems,
    sidebarActions.deleteSavedItem,
    sidebarActions.getRecentItems
  ]);
  const [getFavorites] = useActions([favoritesActions.getFavorites]);

  const recents = useSelector(state => state.sidebar.recents);
  const savedItems = useSelector(state => state.sidebar.savedItems);
  const favorites = useSelector(state => state.favorites);

  const filtersTypes = [
    { label: 'Jobs', value: 'jobs', fields: jobTableLabels },
    {
      label: 'Cpt Reports',
      value: 'vessel_reports',
      fields: ['vessel_reports']
    },
    { label: 'Persons', value: 'parties', fields: ['companies', 'persons'] },
    { label: 'Vessels', value: 'vessels' },
    {
      label: 'Tools',
      value: 'tools',
      fields: [
        'estimations',
        'market-indexes',
        'bunker-prices',
        'ports',
        'document_manager_vessels',
        'document_manager_vessels_per_document',
        'document_manager_crew',
        'document_manager_crew_documents',
        'ticketing_events',
        'ticketing_crew'
      ]
    },
    {
      label: 'Form Fields',
      value: 'form_fields'
    },
    {
      label: 'Crew List',
      value: 'crew_list'
    },
    {
      label: 'Evaluations',
      value: 'crew_evaluation_obligations'
    },
    {
      label: 'Budget',
      value: 'accounting_budget'
    },
    {
      label: 'Purchasing Requisitions',
      value: 'purchasing_requisitions'
    },
    {
      label: 'Purchasing Delivered',
      value: 'purchasing_requisitions_ev'
    }
  ];

  useEffect(() => {
    if (!savedItems.fetched) {
      getSavedItems({ current_page: 1, per_page: savedItems.paging.per_page, types: [] });
    }
    getRecentItems();

    let searchSections = document.getElementById('search-sections');

    searchSections.addEventListener('scroll', handleScroll);

    return function cleanup() {
      searchSections.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const sections = document.getElementById('search-sections');
    const header = document.getElementById('search-header');

    if (sections.scrollTop > 0 && !header.classList.contains('shadow-active')) {
      header.classList.add('shadow-active');
    } else if (sections.scrollTop === 0) {
      header.classList.remove('shadow-active');
    }
  };

  const handleFilterType = type => {
    const { types } = savedItems;
    const newTypes = type.fields ? [...type.fields] : [type.value];
    let updated = [...types];

    newTypes.forEach(t => {
      if (updated.find(x => x === t)) {
        updated = _remove(updated, n => n !== t);
      } else {
        updated.push(t);
      }
    });

    getSavedItems({ current_page: 1, per_page: savedItems.paging.per_page, types: updated });
  };

  const handleItemDelete = async () => {
    const res = await deleteSavedItem({ id: deleteItem ? deleteItem.id : null });

    if (res) {
      const { searchId, ...rest } = parseQueryParams(location.search);

      if (['comparisons', 'table'].includes(deleteItem.item_type)) {
        if (deleteItem.favourites_count) {
          getFavorites({ current_page: 1, per_page: favorites.paging.per_page });
        }

        if (searchId == deleteItem.item.id) {
          history.push({
            pathname: location.pathname,
            search: `?${stringifyObj(rest)}`
          });
        }
      } else if (deleteItem.item_type === 'dashboard') {
        if (location.pathname === `${paths.dashboard}/${deleteItem.item.id}`) {
          history.push('/');
        } else {
          fetchDashboards();
        }
      }

      setDeleteItem(null);
    }
  };

  return (
    <div className="side-search-wrapper d-flex flex-column">
      <SearchHeader getSavedItems={getSavedItems} />

      <div className="side-search-sections gray-scrollbar" id="search-sections">
        <div className="side-search-sections--part mb-5 pe-2">
          <div className="side-search-sections--part-head ps-2">RECENTS</div>
          {!recents.length ? (
            <span className="ps-2">No recent items yet.</span>
          ) : (
            <div className="side-search-sections--part-items">
              {recents.map(item => (
                <RenderItem
                  key={item.id}
                  item={item}
                  saved={true}
                  togglePanel={togglePanel}
                  onDeleteClick={setDeleteItem}
                />
              ))}
            </div>
          )}
        </div>
        <div className="side-search-sections--part pe-2">
          <div className="side-search-sections--part-head ps-2">FILTERED</div>
          <div className="side-search-sections--part-items">
            {savedItems.data.map(item => (
              <RenderItem
                key={item.id}
                item={item}
                saved={true}
                togglePanel={togglePanel}
                onDeleteClick={setDeleteItem}
              />
            ))}
          </div>
        </div>

        {savedItems.paging.last_page > 1 ? (
          <div className="side-search-sections--pagination d-flex align-items-center justify-content-end ps-2 pe-2 mt-5">
            {renderPagination(savedItems, getSavedItems)}
          </div>
        ) : null}
      </div>

      <div className="side-search-sections--filters mt-3 pt-2 mx-2 ">
        <Slider {...slickSettings}>
          {filtersTypes.map(type => (
            <Button
              key={type.value}
              color="link"
              type="button"
              size={'sm'}
              onClick={() => handleFilterType(type)}
              className={
                (type.fields && type.fields.every(v => savedItems.types.includes(v))) ||
                savedItems.types.includes(type.value)
                  ? 'active'
                  : ''
              }
            >
              {type.label}
            </Button>
          ))}
        </Slider>
      </div>

      <DangerousActionModal
        transparent
        isOpen={deleteItem !== null}
        header="Delete Filter"
        body={<div className="text-center">Are you sure you want to delete this filter?</div>}
        actionText="DELETE"
        onAccept={handleItemDelete}
        closeModal={() => setDeleteItem(null)}
      />
    </div>
  );
};

export default Search;
