import { useState } from 'react';
import { Row, Col, Collapse } from 'reactstrap';

import { useSelector } from 'react-redux';

import {
  selectActiveRequisitionCode,
  selectRequisitonVesselOrCompany,
  selectSuppliersData
} from 'common/components/purchasing/requisition/store/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { shouldIncludeDefaultEmailAttachments } from '@/common/components/purchasing/requisition/store/actions.ts';

import Select from 'common/components/form/inputs/Select';
import Input from 'common/components/form/inputs/Input';
import ListSelect from 'common/components/selectors/ListSelect';

import EmailField from './email-field';
import { useAppDispatch } from '@/store/hooks';

const Group = ({ label, children }) => {
  return (
    <div className="email-form__group">
      <div className="email-form__group--label">{label}:</div>
      {children}
    </div>
  );
};

const EmailFormCollapsedFields = ({ emailStatuses, fields, selectField, isVisible }) => {
  const [openCC, setOpenCC] = useState(false);
  const [openBCC, setOpenBCC] = useState(false);
  const dispatch = useAppDispatch();

  const requisitionCode = useSelector(selectActiveRequisitionCode);
  const emailTamplates = useSelector(state => selectListOptionsFromStore(state, 'email-templates'));
  const requisitionVesselOrCompany = useSelector(selectRequisitonVesselOrCompany);
  const suppliersData = useSelector(selectSuppliersData);

  const suppliers = Object.keys(suppliersData).map(supplier_id => suppliersData[supplier_id]);

  const loadEmailSubject = supplierStatus => {
    const subjectValue = `${requisitionVesselOrCompany}, ${supplierStatus?.label.toUpperCase()} ${requisitionCode}`;

    selectField('subject')(subjectValue);
  };

  const findEmailId = email => {
    return suppliers?.find(supplier => supplier?.supplier_email === email)?.id || email;
  };

  const loadEmailTemplate = template => {
    selectField('from')(template?.from);
    selectField('cc')(
      template?.cc?.map(email => ({ id: findEmailId(email), supplier_email: email }))
    );
    selectField('bcc')(
      template?.bcc?.map(email => ({ id: findEmailId(email), supplier_email: email }))
    );
    selectField('body')(template?.body);

    if (template?.cc?.length) {
      setOpenCC(true);
    }

    if (template?.bcc?.length) {
      setOpenBCC(true);
    }
  };

  return (
    <Collapse isOpen={isVisible}>
      <Row className="cmb-12 align-items-center">
        <Col xs={4} className="text-violet fs-12 fw-medium">
          I send email for
        </Col>
        <Col>
          <Select
            placeholder={'Select status'}
            className="mb-0"
            onChange={(value, option) => {
              selectField('status_id')(value);
              loadEmailSubject(option);
              dispatch(shouldIncludeDefaultEmailAttachments(true));
            }}
            isAsync={false}
            options={emailStatuses}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            {...fields.status_id}
          />
        </Col>
      </Row>
      {emailTamplates?.length ? (
        <Row className="cmb-12 align-items-center">
          <Col xs={4} className="text-violet fs-12 fw-medium">
            Choose template
          </Col>
          <Col>
            <ListSelect
              placeholder="Select template"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={(value, option) => {
                selectField('email_template_id')(value);
                loadEmailTemplate(option);
              }}
              params={{ type: 'purchasing_requisition' }}
              list="email-templates"
              className="mb-0"
              {...fields.email_template_id}
            />
          </Col>
        </Row>
      ) : null}

      <Group label={'From'}>
        <Input
          className="email-form__group--from"
          onChange={e => selectField('from')(e.target.value)}
          {...fields.from}
        />
      </Group>

      <Group label={'To'}>
        <div
          className="email-form__group--cc email-form__group--bcc"
          onClick={() => setOpenBCC(e => !e)}
        >
          BCC
        </div>

        <div className="email-form__group--cc" onClick={() => setOpenCC(!openCC)}>
          CC
        </div>

        <EmailField options={suppliers} type="to" onChange={selectField('to')} {...fields.to} />
      </Group>

      {openCC ? (
        <Group label={'CC'}>
          <EmailField options={suppliers} onChange={selectField('cc')} {...fields.cc} />
        </Group>
      ) : null}

      {openBCC ? (
        <Group label={'BCC'}>
          <EmailField
            options={suppliers}
            type="bcc"
            onChange={selectField('bcc')}
            {...fields.bcc}
          />
        </Group>
      ) : null}

      <Group label={'Subject'}>
        <Input
          className="email-form__group--subject"
          onChange={e => selectField('subject')(e.target.value)}
          {...fields.subject}
        />
      </Group>
    </Collapse>
  );
};

export default EmailFormCollapsedFields;
