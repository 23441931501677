import { css } from '@emotion/react';

import Drawer, { DrawerHeader, DrawerBody } from 'common/components/drawer';
import PropTypes from 'prop-types';
import {
  selectIsSummaryViewActive,
  selectActiveRequisitionID,
  shouldShowReviewProcessButton,
  isPurchasingCommunicationDrawerOpen
} from 'common/components/purchasing/requisition/store/selectors';
import { selectRequisitionIsLoading } from 'store/purchasing/requisition-summary/selectors';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRouter from 'use-react-router';
import { toggleSummaryView } from 'common/components/purchasing/requisition/store/actions';
import { getRequisitionSummary } from 'store/purchasing/requisition-summary/actions';
import {
  getEntityReviewProcess,
  checkIfReviewProcessIsEnabled
} from 'common/components/review-process/store/actions';
import {
  shouldCreateReviewProcess,
  selectReviewProcessFormDrawerIsOpen
} from 'common/components/review-process/store/selectors';
import entityActions from 'common/components/review-process/entity-actions';
import TopBar from 'common/components/purchasing/requisition/components/TopBar';
import Findings from 'common/components/purchasing/requisition/components/Findings';

import Spinner from 'common/components/general/Spinner';
import Header from './Header';
import Suppliers from './Suppliers';
import Categories from './categories';
import ReviewProcess from './review-process';
import RemarksAndAttachments from './RemarksAndAttachments';
import { Row, Col } from 'reactstrap';
import { getPurchasingRequisitionItems } from 'common/components/purchasing/requisition/store/actions';
import TotalBlackBox from '@/common/components/purchasing/requisition/header/components/total-black-box';

const Summary = ({ requisitionId, setSummaryRequisitionId, isWidget }) => {
  const { match } = useRouter();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectRequisitionIsLoading);
  const isOpen = useSelector(selectIsSummaryViewActive);
  const isCommunicationDrawerOpen = useSelector(isPurchasingCommunicationDrawerOpen);
  const isFormDrawerOpen = useSelector(selectReviewProcessFormDrawerIsOpen);

  const activeRequisitionID = useSelector(selectActiveRequisitionID);
  const id = isWidget ? requisitionId : requisitionId || match.params.id;
  const isInitialized = !isLoading && activeRequisitionID && isOpen;

  const reviewProcessVisible = useSelector(state =>
    shouldCreateReviewProcess(state, isWidget ? requisitionId : activeRequisitionID)
  );
  const showReviewProcessButton = useSelector(shouldShowReviewProcessButton);

  const initReviewProcess = useCallback(
    async id => {
      try {
        const params = {
          entity_id: id,
          review_process_action: entityActions.purchasingRequisitionApproval.label
        };

        const res = await dispatch(getEntityReviewProcess(params));

        if (!res?.id) {
          dispatch(checkIfReviewProcessIsEnabled(params));
        }
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch]
  );

  const initRequisition = useCallback(
    async id => {
      try {
        await dispatch(getRequisitionSummary({ id, init_state: requisitionId })).unwrap();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, requisitionId]
  );

  useEffect(() => {
    if (isOpen && id) {
      initRequisition(id);
      initReviewProcess(id);
    }
  }, [id, isOpen, initRequisition, initReviewProcess]);

  const onClose = async () => {
    await dispatch(
      getPurchasingRequisitionItems({
        id: activeRequisitionID
      })
    );
    dispatch(toggleSummaryView(false));

    if (setSummaryRequisitionId) setSummaryRequisitionId(null);
  };

  return (
    <Drawer
      css={
        isOpen
          ? isFormDrawerOpen
            ? maximizedDrawerStyles
            : isCommunicationDrawerOpen
            ? minifiedDrawerStyles
            : ''
          : ''
      }
      isOpen={isOpen}
      size="lg"
      close={onClose}
    >
      <DrawerHeader className="py-1">
        {isInitialized && (
          <Header requisitionId={requisitionId} setSummaryRequisitionId={setSummaryRequisitionId} />
        )}
      </DrawerHeader>
      <DrawerBody className="ps-0 pe-2 overflow-hidden">
        {isLoading ? (
          <Spinner className="mt-2 ms-3" />
        ) : isInitialized ? (
          <Row className="h-100p" noGutters>
            <Col xs={8} className="pe-1 border-end h-100p">
              <div className="h-100p overflow-y pe-2 ps-3">
                <RemarksAndAttachments />
                <TopBar className="mt-2" isSummary />
                <TotalBlackBox className="mb-1" />
                <Findings isSummary />
                <Suppliers />
                <Categories />
              </div>
            </Col>
            <Col xs={4} className="ps-3 h-100p overflow-y">
              {reviewProcessVisible && showReviewProcessButton ? <ReviewProcess /> : null}
            </Col>
          </Row>
        ) : null}
      </DrawerBody>
    </Drawer>
  );
};

const maximizedDrawerStyles = css`
  width: calc(100vw - 25rem) !important;
`;

const minifiedDrawerStyles = css`
  transform: translateX(calc(100vw - 65.5rem)) !important;
`;

Summary.propTypes = {
  requisitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isWidget: PropTypes.bool,
  setSummaryRequisitionId: PropTypes.func
};

export default Summary;
