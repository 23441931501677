import React, { useEffect, useState } from 'react';
import {
  selectIsSummaryViewActive,
  selectActiveRequisitionID
} from 'common/components/purchasing/requisition/store/selectors';
import {
  selectActiveEntityId,
  selectReviewProcessSteps,
  selectReviewProcessFormDrawerIsOpen,
  isMandatoryStepsCompleted
} from 'common/components/review-process/store/selectors';
import {
  setActiveReviewProcessEntityId,
  setReviewProcessFormDrawerIsOpen,
  createReviewProcessSubstepFormSubmission
} from 'common/components/review-process/store/actions';
import { replaceReviewProcessAction } from 'common/components/review-process/store/actions-ts';
import { getFormSubmission, updateFormSubmission } from 'store/forms/actions';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import Step from 'common/components/review-process/steps';
import History from 'common/components/review-process/components/history';
import FormsDrawer from 'common/components/forms/drawer';
import { getEntityReviewProcess } from 'common/components/review-process/store/actions';
import entityActions from 'common/components/review-process/entity-actions';
import Notes from 'common/components/review-process/components/notes';
import paths from 'routing/routes/_paths';
import { Button } from 'reactstrap';
import {
  selectReviewProcessTemplateMatchedWithEntity,
  selectReviewProcessId
} from '@/common/components/review-process/store/selectors-ts.ts';
import { checkIfEligibleForPendingReview } from '@/common/components/purchasing/requisition/store/actions';

const ReviewProcess = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsSummaryViewActive);
  const [isLoading, setIsLoading] = useState(false);

  const activeRequisitionID = useAppSelector(selectActiveRequisitionID);

  const reviewProcessIsCompleted = useAppSelector(state =>
    isMandatoryStepsCompleted(state, activeRequisitionID)
  );

  const activeEntityId = useAppSelector(selectActiveEntityId);
  const reviewProcessSteps = useAppSelector(
    state => selectReviewProcessSteps(state, activeEntityId) || []
  );

  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, activeEntityId));

  const reviewProcessTemplateMatchedWithEntity = useAppSelector(state =>
    selectReviewProcessTemplateMatchedWithEntity(state, activeEntityId)
  );

  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedSubStepID, setSelectedSubStepID] = useState(null);
  const [selectedStepFormID, setSelectedStepFormID] = useState(null);
  const [selectedFormSubmissionID, setSelectedFormSubmissionID] = useState(null);
  const [drawerType, setDrawerType] = useState('view');
  const isFormDrawerOpen = useAppSelector(selectReviewProcessFormDrawerIsOpen);

  const fetchReviewProcess = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        getEntityReviewProcess({
          entity_id: activeEntityId,
          review_process_action: entityActions.purchasingRequisitionApproval.label
        })
      );
      await dispatch(checkIfEligibleForPendingReview({ id: activeRequisitionID }));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFormSelect = (form, formSubmission, step_form_id, sub_step_id) => {
    if (formSubmission) {
      window.open(`${paths.forms}/${formSubmission?.id}`, '_blank');
    } else {
      setSelectedForm(form);
      setSelectedStepFormID(step_form_id);
      setSelectedSubStepID(sub_step_id);
      setDrawerType(!formSubmission ? 'edit' : 'view');
      dispatch(setReviewProcessFormDrawerIsOpen(true));
    }
  };

  const onFormClose = () => {
    setSelectedForm(null);
    setSelectedStepFormID(null);
    setSelectedSubStepID(null);
    setSelectedFormSubmissionID(null);

    setDrawerType('view');
    dispatch(setReviewProcessFormDrawerIsOpen(false));

    fetchReviewProcess();
  };

  const onFormSubmit = async params => {
    if (selectedFormSubmissionID) {
      await dispatch(updateFormSubmission({ ...params, id: selectedFormSubmissionID }));
      await dispatch(getFormSubmission({ id: selectedFormSubmissionID }));
    } else {
      await dispatch(
        createReviewProcessSubstepFormSubmission({
          ...params,
          substep_id: selectedSubStepID,
          substep_form_id: selectedStepFormID
        })
      );
    }
  };

  const onReset = async () => {
    await dispatch(replaceReviewProcessAction({ id: reviewProcessId }));
    await fetchReviewProcess();
  };

  useEffect(() => {
    if (isOpen) dispatch(setActiveReviewProcessEntityId(activeRequisitionID));
  }, [isOpen, dispatch, activeRequisitionID]);

  return (
    <div>
      {!reviewProcessTemplateMatchedWithEntity && !reviewProcessIsCompleted ? (
        <div className="d-flex align-items-center justify-content-between pe-1 pt-2">
          <div className="fs-12 text-violet">* The terms of the process have changed.</div>
          <Button size="sm" color="primary" className="text-white" onClick={onReset}>
            Reset the Approval Process
          </Button>
        </div>
      ) : null}

      <div className="pe-1 py-3">
        {reviewProcessSteps.map((step, index) => (
          <Step
            key={step.id}
            index={index}
            step={step}
            steps={reviewProcessSteps}
            loading={isLoading}
            onFormSelect={onFormSelect}
            refetchDrawer={fetchReviewProcess}
            entityId={activeEntityId}
            collapsable={true}
          />
        ))}
      </div>
      <Notes activeEntityId={activeEntityId} />
      <div className="mt-6">
        <History reviewProcessSteps={reviewProcessSteps} />
      </div>
      <FormsDrawer
        drawerType={drawerType}
        drawer={{ isOpen: isFormDrawerOpen && activeEntityId, open: () => null }}
        onDrawerClose={onFormClose}
        initialForm={selectedForm}
        initialFormSubmissionID={selectedFormSubmissionID}
        onSaveFormSubmission={onFormSubmit}
        hiddenFields={{ vessel: true, type: true }}
      />
    </div>
  );
};

export default ReviewProcess;
