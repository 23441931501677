import { useEffect, useState, useCallback } from 'react';
import { refetchAsyncOptions } from 'utils/helpers';

import _isNumber from 'lodash/isNumber';
import _isArray from 'lodash/isArray';

const TrainingDropdownView = ({ value, seperator = ', ' }) => {
  const [trainings, setTrainings] = useState([]);

  const getTrainingOptions = useCallback(async () => {
    const data = await refetchAsyncOptions('crew-training-types', {
      is_onboard: true
    });
    setTrainings(data);
  }, []);

  useEffect(() => {
    if (_isNumber(value)) {
      getTrainingOptions();
    }
  }, [getTrainingOptions, value]);

  const parsedValue = _isNumber(value)
    ? trainings.find(d => d.id === value)?.name || '-'
    : _isArray(value)
    ? value.map((v, i) => `${v?.name}${i === value?.length - 1 ? '' : seperator}`)
    : value;

  return parsedValue;
};

export default TrainingDropdownView;
