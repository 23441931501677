import { useEffect, useCallback, useState, FC } from 'react';
import { Button, Row, Col } from 'reactstrap';
import {
  selectFieldValuesType,
  selectFieldValues,
  selectSimilaritiesData
} from '@/common/components/similarities/store/selectors';
import { getPartiesSimilarityAction } from '@/common/components/similarities/store/actions';
import {
  FieldValuesType,
  triggerModalBySetFieldValues
} from '@/common/components/similarities/store/slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { PartySimilarityType } from '@/common/types/parties';

import ShadowBox from 'common/components/general/ShadowBox';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Modal from 'common/components/modals/BasicModal';
import SvgRender from 'common/components/general/SvgRender';
import archive from 'common/assets/svg/common/modal_archive.svg';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';

type PartiesSimilarityModalProps = {
  onSave: (fieldValues: FieldValuesType) => void;
  label: 'person' | 'company';
  container?: string;
  backdropClassName?: string;
};

const PartiesSimilarityModal: FC<PartiesSimilarityModalProps> = ({
  onSave,
  label = 'person',
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasRequestedSimilarities, setHasRequestedSimilarities] = useState(false);
  const [triggerOnSubmit, setTriggerOnSubmit] = useState(false);

  const dispatch = useAppDispatch();

  const similaritiesData = useAppSelector(selectSimilaritiesData) as PartySimilarityType[];
  const fieldValues = useAppSelector(selectFieldValues);

  const fieldsValueType = useAppSelector(selectFieldValuesType);
  const previouslyFetchedFieldsValueType = usePrevious(fieldsValueType);

  const onCloseModal = useCallback(() => {
    dispatch(triggerModalBySetFieldValues(null));
    setShowModal(false);
    setHasRequestedSimilarities(false);
    setTriggerOnSubmit(false);
  }, [dispatch]);

  const onSubmit = useCallback(
    async (params?: FieldValuesType | null) => {
      if (!params) return;

      try {
        setTriggerOnSubmit(false);
        setIsDisabled(true);
        await onSave(params);

        onCloseModal();
      } catch (err) {
        setIsDisabled(false);
        console.error(err);
      }
    },
    [onSave, onCloseModal]
  );

  const fetchPartiesSimilarity = useCallback(async () => {
    if (!fieldsValueType) return;
    if (fieldsValueType !== label) return;

    try {
      setHasRequestedSimilarities(true);

      const res = await dispatch(getPartiesSimilarityAction()).unwrap();
      const hasSimilarities = !!res?.data.length;

      if (hasSimilarities) {
        setShowModal(true);
      } else {
        setTriggerOnSubmit(true);
      }
    } catch (err) {
      console.error(err);
      setHasRequestedSimilarities(false);
    }
  }, [dispatch, fieldsValueType, label]);

  useEffect(() => {
    if (!hasRequestedSimilarities) fetchPartiesSimilarity();
  }, [hasRequestedSimilarities, fetchPartiesSimilarity]);

  useEffect(() => {
    if (fieldsValueType && previouslyFetchedFieldsValueType !== fieldsValueType) {
      setHasRequestedSimilarities(false);
    }
  }, [previouslyFetchedFieldsValueType, fieldsValueType]);

  useEffect(() => {
    if (triggerOnSubmit) onSubmit(fieldValues);
  }, [fieldValues, onSubmit, triggerOnSubmit]);

  return (
    <Modal
      header={
        <div className="d-flex align-items-center fs-16">
          Duplicated <span className="text-capitalize cms-4">{label}</span>
        </div>
      }
      body={
        <div className="d-flex flex-column">
          <div className="fs-12 text-primary fw-medium mb-3">
            Orca application has identified an existing {label} sharing similar characteristics. Are
            you sure you wish to proceed with creating a new one?
          </div>

          {similaritiesData.length > 0 ? (
            <div className="max-height-340 overflow-y-scroll cps-4 cpe-4 overflow-x-hidden gray-scrollbar">
              {similaritiesData.map((party: PartySimilarityType) => (
                <ShadowBox key={party.id} color="light-2" className="cmb-4" flat whiteOnHover>
                  <Row className="cps-12 h-24 align-items-center cmt-4">
                    <Col
                      className={`flex-1 text-primary fs-12 fs-width-medium ${
                        party.is_archived ? 'd-flex align-items-center text-violet' : 'text-primary'
                      }`}
                    >
                      {party.is_archived ? (
                        <SvgRender
                          src={archive}
                          style={{ width: '12px', height: '12px' }}
                          className="me-1"
                        />
                      ) : null}

                      <TextWithTooltip className={`h-auto`}>
                        {party.company_name || party.full_name}
                      </TextWithTooltip>
                    </Col>

                    <Col className="text-violet fs-12 fs-width-bold text-end pe-3">
                      Matched with {party.similar_field}
                    </Col>
                  </Row>
                </ShadowBox>
              ))}
            </div>
          ) : null}
        </div>
      }
      isOpen={showModal}
      toggle={onCloseModal}
      footer={
        <>
          <Button color="cancel" disabled={isDisabled} onClick={onCloseModal}>
            CANCEL
          </Button>
          <Button color="primary" disabled={isDisabled} onClick={() => onSubmit(fieldValues)}>
            PROCEED
          </Button>
        </>
      }
      {...rest}
    />
  );
};

export default PartiesSimilarityModal;
