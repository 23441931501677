import { BUDGET_TYPE } from './components/drawer/config';
import moment from 'moment';

import PurchasingCategorySelector from 'common/components/selectors/PurchasingCategorySelector';
import TripTicketTypeSelector from 'common/components/selectors/TripTicketTypeSelector';
import AccountsSelector from 'common/components/selectors/AccountsSelector';

import accounting from 'common/assets/svg/accounting/calculate.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import ticketing from 'assets/svg/sidebar/ticketing.svg';
import { numberToStr, strToNumber } from 'common/utils/numbers';

export const configSelectorByType = type => {
  switch (type) {
    case 'ticketing':
      return {
        label: 'Ticket Type',
        infoTitle: 'Ticketing',
        list: 'ticketing-types',
        fieldKey: 'budgetable',
        name: 'Ticket Types',
        budgetable_type: 'ticketing_type', // We need this information separated from type for future purpose
        icon: ticketing
      };

    case 'purchasing':
      return {
        label: 'Purchasing Category',
        infoTitle: 'Purchasing',
        list: 'purchasing-categories',
        fieldKey: 'budgetable',
        name: 'Purchasing Categories',
        budgetable_type: 'purchasing_category', // We need this information separated from type for future purpose
        icon: purchasing
      };

    case 'accounts':
      return {
        label: 'Ledger Card',
        infoTitle: 'Ledger Cards',
        list: 'accounts',
        fieldKey: 'budgetable',
        budgetable_type: 'account', // We need this information separated from type for future purpose
        name: 'Accounts',
        icon: accounting
      };

    default:
      return {
        label: null,
        infoTitle: null,
        list: null,
        fieldKey: null
      };
  }
};

export const constructDynamicTableColumns = type => {
  const defaultSelectorParams = {
    type: 'collection',
    className: 'd-none',
    componentRest: { isMulti: true },
    canFilter: true,
    sort: false,
    hidden: true
  };

  switch (type) {
    case 'purchasing':
      return [
        {
          component: PurchasingCategorySelector,
          header: 'Purchasing Categories',
          key: 'something',
          ...defaultSelectorParams
        }
      ];
    case 'ticketing':
      return [
        {
          component: TripTicketTypeSelector,
          header: 'Ticketing Types',
          key: 'something',
          ...defaultSelectorParams
        }
      ];
    case 'accounts':
      return [
        {
          component: AccountsSelector,
          header: 'Accounts',
          key: 'something',
          ...defaultSelectorParams
        }
      ];
    default:
      return [{}];
  }
};

export const constructPeriodParams = periods => {
  return {
    period_type: periods.period_type,
    ...(periods.period_type === 'c'
      ? {
          period_start: moment(periods.period_start).format('YYYY-MM-DD'),
          period_end: moment(periods.period_end).format('YYYY-MM-DD')
        }
      : {
          period_year: periods.period_year
        })
  };
};

export const getPeriodDates = period => {
  const type = period?.period_type;
  const periodType = type?.slice(0, 1);
  const periodNumber = type?.slice(1, 2);
  const year = period?.period_year;

  switch (periodType) {
    case 'q':
      return {
        periodStart: moment(year, 'YYYY')
          .quarter(periodNumber)
          .startOf('quarter')
          .format('DD/MM/YYYY'),
        periodEnd: moment(year, 'YYYY').quarter(periodNumber).endOf('quarter').format('DD/MM/YYYY')
      };
    case 'y':
      return {
        periodStart: moment(year, 'YYYY').startOf('year').format('DD/MM/YYYY'),
        periodEnd: moment(year, 'YYYY').endOf('year').format('DD/MM/YYYY')
      };
    case 'h':
      return periodNumber === '1'
        ? {
            periodStart: moment(year, 'YYYY').startOf('year').format('DD/MM/YYYY'),
            periodEnd: moment(year, 'YYYY').endOf('year').subtract(6, 'month').format('DD/MM/YYYY')
          }
        : {
            periodStart: moment(year, 'YYYY').startOf('year').add(6, 'month').format('DD/MM/YYYY'),
            periodEnd: moment(year, 'YYYY').endOf('year').format('DD/MM/YYYY')
          };
    case 'c':
      return {
        periodStart: period?.period_start?.format('DD/MM/YYYY'),
        periodEnd: period?.period_end?.format('DD/MM/YYYY')
      };
    default:
      return { periodStart: null, periodEnd: null };
  }
};

export const getBudgetType = ({ is_for_all_companies, company }) => {
  if (is_for_all_companies) {
    return BUDGET_TYPE.FOR_ALL_COMPANIES;
  } else if (company) {
    return BUDGET_TYPE.FOR_SPECIFIC_COMPANY;
  }

  return null;
};

export const constructBudgetTypeParams = ({ company }) => ({
  company_id: company?.id || null
});

export const constructBudgetTypeComponent = data => {
  const type = getBudgetType(data);
  let text = '-';

  if (type === BUDGET_TYPE.FOR_ALL_COMPANIES) {
    text = 'All Companies';
  } else if (type === BUDGET_TYPE.FOR_SPECIFIC_COMPANY) {
    text = data?.company?.name || '-';
  }

  return text;
};

export const calculateUSDPerDay = (amount, startDate, endDate) => {
  if (!amount) return '-';

  const parseAmount = strToNumber(amount);

  const startMoment = moment(startDate, 'DD/MM/YYYY');
  const endMoment = moment(endDate, 'DD/MM/YYYY');

  // Calculate the number of days in the period
  const days = endMoment.diff(startMoment, 'days') + 1; // Adding 1 to include both start and end dates

  // Calculate USD per day
  const usdPerDay = parseAmount / days;

  return numberToStr(usdPerDay, 2, 2);
};
