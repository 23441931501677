import { FC } from 'react';
import clsx from 'clsx';

import { useAppSelector } from '@/store/hooks';
import Box from './Box';
import { useGetPurchasingRequisitionSupplierTotals } from '@/api/purchasing/queries.ts';
import { selectActiveRequisitionID } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';

type TotalBlackBoxProps = {
  className?: string;
};

const TotalBlackBox: FC<TotalBlackBoxProps> = ({ className }) => {
  const activeRequisitionID = useAppSelector(selectActiveRequisitionID);
  const { data: allSupplierTotals } = useGetPurchasingRequisitionSupplierTotals({
    id: activeRequisitionID
  });

  return (
    <div className={clsx('d-flex align-items-center', className)}>
      <Box
        title="Proposed USD"
        type="proposed"
        tooltip="This is the Total of all Proposed items from all the suppliers."
        value={allSupplierTotals?.proposed_price}
        className="cme-4"
      />

      <Box
        title="Approved USD"
        type="approved"
        tooltip="This is the Total of all Approved items from all the suppliers."
        value={allSupplierTotals?.approved_price}
        className="cme-4"
      />

      <Box
        title="Delivered USD"
        type="delivered"
        tooltip="This is the Total of all Delivered items in the Delivery Reports."
        value={allSupplierTotals?.delivered_price}
      />
    </div>
  );
};

export default TotalBlackBox;
