import { FC, ReactNode } from 'react';
import { SerializedStyles } from '@emotion/react';
import WhiteShadowButton from '@/common/components/buttons/WhiteShadowButton.tsx';

import SvgRender from 'common/components/general/SvgRender';
import AuthCheck from '@/components/permissions/AuthCheck';

export type EmptyPageProps = {
  className?: string;
  pageText: string;
  pageIcon?: string;
  subTitle?: ReactNode;
  buttonText?: string;
  buttonType?: string;
  buttonOnClick?: () => void;
  buttonClassName?: string;
  svgClassName?: string;
  permissions?: string[];
  backgroundSvgContainerClassName?: string;
  css?: SerializedStyles;
};

const EmptyPage: FC<EmptyPageProps> = ({
  className,
  pageText,
  pageIcon,
  subTitle,
  buttonText,
  buttonType,
  buttonOnClick,
  buttonClassName,
  svgClassName,
  permissions,
  backgroundSvgContainerClassName = '',
  css // Override styles with emotion
}) => {
  return (
    <div className={`empty-page ${className || ''}`} css={css}>
      {pageIcon ? (
        <div className={`empty-page__bg ${backgroundSvgContainerClassName}`}>
          <SvgRender src={pageIcon} style={{ width: '100%', height: '100%' }} />
        </div>
      ) : null}
      <div className={`empty-page__text ${subTitle ? 'mb-1' : ''}`}>{pageText}</div>
      {subTitle ? <div className="empty-page__subTitle">{subTitle}</div> : null}

      {buttonOnClick ? (
        <AuthCheck permissions={permissions}>
          <WhiteShadowButton
            text={buttonText}
            type={buttonType}
            onClick={buttonOnClick}
            className={buttonClassName}
            svgClassName={svgClassName}
          />
        </AuthCheck>
      ) : null}
    </div>
  );
};

export default EmptyPage;
