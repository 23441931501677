import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';

import Status from 'common/components/general/Status';
import Departments from 'common/components/general/Departments';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';
import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import SvgRender from 'common/components/general/SvgRender';
import Priority from 'common/components/general/Priority';

import { selectWidgetState } from 'store/dashboards/selectors';

import moment from 'moment';

const Events = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));

  return (
    <div>
      <WidgetTitle id={id} linkTo={paths.events} />
      <WidgetDefaultTable
        id={id}
        columns={[
          {
            header: 'Title',
            key: 'title',
            type: 'string',
            headerClassName: 'text-violet',
            width: 3
          },
          {
            header: 'Type',
            key: 'type',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Date',
            key: 'date',
            type: 'date',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Department',
            key: 'department',
            type: 'string',
            headerClassName: 'text-violet',
            width: 2
          },
          {
            header: 'Status',
            key: 'status',
            type: 'string',
            headerClassName: 'text-violet',
            minWidth: 217,
            maxWidth: 217
          },
          {
            header: 'Vessel',
            key: 'vessel',
            type: 'string',
            headerClassName: 'text-violet'
          },
          {
            header: 'Actions',
            key: 'actions',
            field: 'actions'
          }
        ]}
        rows={{
          title: data => {
            return (
              <Row className="align-items-center text-nowrap overflow-hidden">
                <Col xs="1">
                  <Priority value={data?.importance?.id} />
                </Col>
                <Col xs="10">
                  <Link to={`${paths.events}/${data.id}`} target="_blank">
                    <TextWithTooltip>{data.name}</TextWithTooltip>
                  </Link>
                </Col>
                <Col xs="1" className="ms-auto">
                  {data.is_for_vessel ? (
                    <>
                      <SvgRender
                        id={`event-table-vessel-tooltip-${data.id}`}
                        className={`${
                          data.status?.edit_side !== 'vessel' ? 'text-turquoise' : 'text-violet'
                        } cursor-pointer`}
                        src={vesselIcon}
                        style={{ width: 16, height: 16 }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`event-table-vessel-tooltip-${data.id}`}
                        trigger="hover"
                      >
                        {data?.vessel?.name}
                      </UncontrolledTooltip>
                    </>
                  ) : null}
                </Col>
              </Row>
            );
          },
          type: data => {
            return (
              <Link
                to={`${paths.events}/${data.id}`}
                className="d-flex align-items-center text-nowrap overflow-hidden"
                target="_blank"
              >
                {data?.type?.name}
              </Link>
            );
          },
          date: data => (
            <div>
              {data.date_type === 'single' && data.started_at ? (
                <span>{moment(data.started_at).format('DD/MM/YYYY')}</span>
              ) : data.date_type === 'range' ? (
                <TextWithTooltip>
                  {moment(data.started_at).format('DD/MM/YYYY')} -{' '}
                  {moment(data.ended_at).format('DD/MM/YYYY')}
                </TextWithTooltip>
              ) : (
                '-'
              )}
            </div>
          ),
          department: data => <Departments values={data.departments} />,
          status: data => <Status type="events" value={data.status} />,
          vessel: data =>
            data.vessel && data.vessel.name ? (
              <Link to={`${paths.vessels}/${data.vessel.id}`}>{data.vessel.name}</Link>
            ) : (
              <div className="text-secondary">No Vessel</div>
            ),
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.events}/${data.id}`)
              }
            ]
          })
        }}
        label={label}
        state={state}
      />
    </div>
  );
};

export default Events;
