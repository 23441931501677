import _isString from 'lodash/isString';
import _trim from 'lodash/trim';
import _isBoolean from 'lodash/isBoolean';
import _isPlainObject from 'lodash/isPlainObject';

export const parseBoolean = (value?: any) => {
  if (_isBoolean(value)) return value;

  if (_isString(value)) {
    const trimmedValue = _trim(value);

    if (trimmedValue === 'true') return true;
    if (trimmedValue === 'false') return false;
  }

  return null;
};

export function hashValue(objectToParse: Record<string, unknown> | unknown[]): string {
  return JSON.stringify(objectToParse, (_, val) =>
    _isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key];
            return result;
          }, {} as any)
      : typeof val === 'number' || typeof val === 'boolean'
      ? val.toString()
      : val
  );
}
