import React from 'react';
import paths from '@/routing/routes/_paths.js';
import permissions from '@/common/utils/permissions/constants.js';
import retry from '@/common/utils/lazy-retry.js';

const FormSubmissions = React.lazy(() =>
  retry(() => import('@/views/forms/submissions/index.tsx'))
);
const FormTableReports = React.lazy(() =>
  retry(() => import('@/views/forms/table-reports/index.tsx'))
);
const FormPlanning = React.lazy(() => retry(() => import('@/views/forms/planning/index.jsx')));

const DigitalSetup = React.lazy(() =>
  retry(() => import('@/views/settings/forms/digital/index.jsx'))
);
const FormStatuses = React.lazy(() =>
  retry(() => import('@/views/settings/forms/statuses/index.jsx'))
);
const UploadForms = React.lazy(() =>
  retry(() => import('@/views/settings/forms/upload/index.jsx'))
);
const FormFields = React.lazy(() => retry(() => import('@/views/settings/forms/fields/index.jsx')));
const FormPlanningSettings = React.lazy(() =>
  retry(() => import('@/views/settings/forms/planning/index.jsx'))
);

const FormObligations = React.lazy(() =>
  retry(() => import('@/common/components/form-obligations/index.jsx'))
);
const FormObligationSettings = React.lazy(() =>
  retry(() => import('@/views/settings/forms/obligations/index.jsx'))
);

const forms = [
  {
    path: [`${paths.forms}`, `${paths.forms}/:form_id`],
    component: FormSubmissions,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.forms.list.view],
    documentTitle: 'Forms'
  },
  {
    path: [`${paths.form_planning}`, `${paths.form_planning}/:form_id`],
    component: FormPlanning,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.forms.planning.view],
    documentTitle: 'Form Planning'
  },
  {
    path: `${paths.form_obligations}`,
    component: FormObligations,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.forms.obligations.view],
    documentTitle: 'Form Obligations'
  },
  {
    path: `${paths.form_table_reports}`,
    component: FormTableReports,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.forms.reports.view],
    documentTitle: 'Form Table Reports'
  },
  {
    path: `${paths.form_settings}/digital`,
    component: DigitalSetup,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.forms_setup.view],
    documentTitle: 'Digital Forms'
  },
  {
    path: `${paths.form_settings}/statuses`,
    component: FormStatuses,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.statuses.view],
    documentTitle: 'Form Statuses'
  },
  {
    path: `${paths.form_settings}/upload`,
    component: UploadForms,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.forms_setup.view],
    documentTitle: 'Upload Forms'
  },
  {
    path: `${paths.form_settings}/form-fields`,
    component: FormFields,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.form_fields.view],
    documentTitle: 'Form Fields'
  },
  {
    path: `${paths.form_settings}/form-obligations`,
    component: FormObligationSettings,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.form_obligations.view],
    documentTitle: 'Form Obligations'
  },
  {
    path: `${paths.form_settings}/planning`,
    component: FormPlanningSettings,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.forms.planning.view],
    documentTitle: 'Form Planning Settings'
  }
];

export default forms;
