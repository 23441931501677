import { PurchasingRequisitionStatusLabel } from '@/common/types/purchasing.ts';

export type TotalBlackBoxType = 'proposed' | 'approved' | 'delivered';

export const shouldRenderComponent = (
  type: TotalBlackBoxType,
  status?: PurchasingRequisitionStatusLabel
) => {
  if (!status) return false;

  switch (type) {
    case 'proposed':
      return status !== 'draft' && status !== 'rqn' && status !== 'rfq' && status !== 'qtn';
    case 'approved':
      return (
        status !== 'draft' &&
        status !== 'rqn' &&
        status !== 'rfq' &&
        status !== 'qtn' &&
        status !== 'pr'
      );
    case 'delivered':
      return (
        status !== 'draft' &&
        status !== 'rfq' &&
        status !== 'rqn' &&
        status !== 'qtn' &&
        status !== 'pr' &&
        status !== 'po'
      );
      return false;
  }
};
