import {
  ItemSupplierDetailsType,
  ItemType,
  SupplierDetailsType,
  SuppliersWithEquivalentCurrencyType
} from './selectors-ts';
import { strToNumber } from '@/ts-common/utils/numbers';
import _max from 'lodash/max';
import _min from 'lodash/min';
import { PurchasingRequisitionData, Supplier } from '@/common/types/purchasing.ts';

type RequisitionState = {
  visibleSuppliers: number[];
  supplierDetails: SupplierDetailsType;
  suppliersWithEquivalentCurrency: SuppliersWithEquivalentCurrencyType;
  supplierRequisitionID: string;
  fieldKey: string;
  comparisonFieldKey: string;
};

export type RequisitionItemType = {
  requested_quantity: string;
  description: string;
  requested_packaging_id: number;
  id: number;
  [key: string]: any;
};

export const isHighlightedSupplierField = (
  {
    visibleSuppliers,
    supplierDetails,
    supplierRequisitionID,
    suppliersWithEquivalentCurrency,
    fieldKey,
    comparisonFieldKey
  }: RequisitionState,
  type: 'max' | 'min'
): boolean => {
  const itemSupplierRequisitionIDs = Object.keys(supplierDetails).filter(key =>
    visibleSuppliers.includes(+key)
  );

  if (
    suppliersWithEquivalentCurrency.includes(parseInt(supplierRequisitionID)) &&
    !fieldKey.endsWith('base_currency_equivalent')
  ) {
    // When the supplier has a base_currency_equivalent field and this current field is not this one, do not color this field. Instead, color the base_currency_equivalent field.
    return false;
  } else if (itemSupplierRequisitionIDs.length > 1) {
    const supplierFieldValues = itemSupplierRequisitionIDs.map(key =>
      strToNumber(supplierDetails[key]?.[comparisonFieldKey || fieldKey])
    );
    const supplierPrice =
      strToNumber(supplierDetails[supplierRequisitionID]?.[comparisonFieldKey || fieldKey]) || 0;

    const maxSupplierPrice = _max(supplierFieldValues) || 0;
    const minSupplierPrice = _min(supplierFieldValues) || 0;

    if (type === 'max') {
      // Most Expensive is the item for a supplier when: supplier price = max(supplier prices) and supplier price > min(supplier price)
      return supplierPrice === maxSupplierPrice && supplierPrice > minSupplierPrice;
    } else {
      // Cheapest is the item for a supplier when: supplier price = min(supplier prices) and supplier price < max(supplier price)
      return supplierPrice === minSupplierPrice && supplierPrice < maxSupplierPrice;
    }
  }

  return false;
};

export const supplierItemsHaveValue = (
  {
    items,
    fieldKey,
    supplierRequisitionID,
    categoryItems
  }: {
    items: { [itemId: string]: ItemType };
    fieldKey: string;
    supplierRequisitionID: string | number;
    categoryItems?: string[];
  },
  mode = 'and',
  includeZeros = false
): boolean => {
  if (!supplierRequisitionID) return false;

  const itemKeys =
    categoryItems && categoryItems?.length > 0
      ? Object.keys(items).filter(itemId => categoryItems.includes(itemId))
      : Object.keys(items);

  const filledItems = itemKeys.filter(itemId => {
    const value = items[itemId].supplier_details?.[supplierRequisitionID]?.[fieldKey];

    if (!includeZeros) {
      return value && +value !== 0;
    } else {
      return value || value === 0;
    }
  });

  if (mode === 'and') {
    return filledItems.length > 0 && filledItems.length === itemKeys.length;
  } else {
    return filledItems.length > 0 && filledItems.length < itemKeys.length;
  }
};

export const itemsHaveValue = (
  {
    items,
    fieldKey
  }: {
    items: { [itemId: string]: RequisitionItemType };
    fieldKey: string;
  },
  mode = 'and',
  includeZeros = false
): boolean => {
  const itemKeys = Object.keys(items);

  const filledItems = itemKeys.filter(itemId => {
    const item = items[itemId];

    const value = item[fieldKey];

    if (!includeZeros) {
      return value && +value !== 0;
    } else {
      return value || value === 0;
    }
  });

  if (mode === 'and') {
    return filledItems.length > 0 && filledItems.length === itemKeys.length;
  } else {
    return filledItems.length > 0 && filledItems.length < itemKeys.length;
  }
};

export const isInvalidApprovedQuantity = (
  item: ItemSupplierDetailsType | null
): boolean /* True => Invalid, False => Valid */ => {
  if (item === null) {
    return true;
  } else {
    return item.approved_quantity === null || item.approved_quantity === '';
  }
};

export const displaySupplierDeliveryPlace = (
  supplier: Supplier,
  prefix = '- ',
  fullDescription = true
) => {
  const deliveryItineraryPort = fullDescription
    ? supplier.delivery_itinerary_port?.description
    : supplier.delivery_itinerary_port?.port.name;
  const location =
    deliveryItineraryPort || supplier.delivery_port?.name || supplier.delivery_spot || '';

  return `${location ? prefix : ''}${location}`;
};
