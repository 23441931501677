import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import SelectColumnOperation from './SelectColumnOperation';
import SelectValue from './SelectValue';
import DateFilterSelector from '../table/filters/DateFilterSelector';

import types from 'common/utils/filters/operators';
import { formatColumn } from 'common/utils/filters/helpers';
import { useSelector } from 'react-redux';

import _sortBy from 'lodash/sortBy';

const Filter = ({
  columns,
  filter,
  updateFilter,
  removeFilter,
  getTableFilterValue,
  isCreate,
  isHidden,
  isTopFilter,
  customText
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const isOnBoard = useSelector(state => state.isOnBoard);

  useEffect(() => {
    if (filter.column && !filter.operation) {
      updateFilter({ operation: types[filter.column.type].operations[0] });
    } else if (filter.column && filter.operation && filter.value) {
      setIsSearching(true);
    } else if (isSearching) {
      setIsSearching(false);
    }
  }, [filter]);

  return (
    <div
      className={`filter-handler${isHidden ? ' d-none' : ''}${
        isTopFilter ? ' filter-handler--as-top-filter' : ''
      }`}
    >
      <SelectColumnOperation
        customText={customText}
        isCreate={isCreate}
        placeholder={'Select field'}
        value={filter.column}
        onSelect={val => updateFilter({ column: val, operation: null, value: '' })}
        options={_sortBy(
          columns
            .filter(c => c.type && !c.hideFromDropdown)
            .filter(column => (column.hiddenOnBoard && isOnBoard ? false : column))
            .map(col => formatColumn(col)),
          item => item.label
        )}
      />

      {filter.column && (
        <SelectColumnOperation
          isOperation
          value={filter.operation}
          onSelect={val =>
            updateFilter({
              operation: val,
              value:
                filter.value && (filter.column.type === 'date' || 'datetime' || 'date_period')
                  ? ''
                  : filter.value
            })
          }
          options={types[filter.column.type].operations}
        />
      )}

      {filter.column && filter.operation ? (
        // When used separately as a Date TopFilter, display the new DateFilter
        isTopFilter &&
        ['date', 'datetime', 'utc_datetime', 'date_period'].includes(filter.column.type) ? (
          <DateFilterSelector
            filter={filter}
            value={filter.value}
            onChange={value => updateFilter({ operation: filter.operation, value })}
            {...filter.column.componentRest}
          />
        ) : (
          <SelectValue
            filter={filter}
            updateFilter={updateFilter}
            getTableFilterValue={getTableFilterValue}
          />
        )
      ) : null}

      {filter.column && filter.operation && (
        <Button
          onClick={removeFilter}
          type="button"
          className={`filter-handler--clear ${
            isTopFilter ? `filter-handler--clear-top ${filter.value ? 'with-value' : ''}` : ''
          }`}
          color="link"
        />
      )}
    </div>
  );
};

export default Filter;
