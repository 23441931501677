import TYPES from './types';
import _sortBy from 'lodash/sortBy';
import { LOCATION_CHANGE } from 'connected-react-router';
import { arrayMove } from 'react-sortable-hoc';
import { setEventWatchers } from './actions';
import paths from 'routing/routes/_paths';

const INITIAL_EVENT = {
  id: undefined,
  name: '',
  is_scheduled: false,
  date_type: 'single',
  started_at: undefined,
  ended_at: undefined,
  type: undefined,
  status: undefined,
  importance: undefined,
  port: undefined,
  itinerary_port: undefined,
  port_type: 'port', // port or itinerary_port
  vessel: undefined,
  voyage: undefined,
  is_for_vessel: undefined,
  responsible: undefined,
  is_datetime: false,
  department_ids: [],
  departments: [],
  approvers: [],
  remarks: '',
  submitted: null,
  tags: [],
  modules: [],
  is_watching: false,
  event_port: null,
  dates: [],
  ticketing_case: null,
  isMultipleEventsCreate: false,
  savedVessel: null,
  savedItineraryPort: null,
  watchers: [],
  template: null,
  due_date: null
};

const INITIAL_ERRORS = {
  title: '',
  type: '',
  date: '',
  dateInputId: null
};

export const INITIAL_STATE = {
  isOnBoard: true,
  isLocked: false,
  errors: INITIAL_ERRORS,
  event: INITIAL_EVENT,
  isCreating: false,
  loading: false,
  isSaving: false,
  isEditingModules: false,
  activeModulesLabels: [],
  isSubmittingEvent: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SUBMIT_EVENT.START:
      return {
        ...state,
        isSubmittingEvent: true,
        isLocked: true
      };
    case TYPES.SUBMIT_EVENT.SUCCESS:
      return {
        ...state,
        isSubmittingEvent: false,
        isLocked: true,
        event: {
          ...state.event,
          ...payload
        }
      };
    case TYPES.SUBMIT_EVENT.ERROR:
      return {
        ...state,
        isSubmittingEvent: false,
        isLocked: false
      };
    case TYPES.SET_IS_LOCKED:
      return {
        ...state,
        isLocked: payload
      };
    case TYPES.SET_IS_ON_BOARD:
      return {
        ...state,
        isOnBoard: payload
      };
    case TYPES.WATCH_EVENT.START:
      return {
        ...state,
        event: {
          ...state.event,
          is_watching: true
        }
      };
    case TYPES.WATCH_EVENT.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          is_watching: false
        }
      };

    case TYPES.UNWATCH_EVENT.START:
      return {
        ...state,
        event: {
          ...state.event,
          is_watching: false
        }
      };

    case TYPES.UNWATCH_EVENT.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          is_watching: true
        }
      };

    case TYPES.REMOVE_MODULE.START:
      return {
        ...state,
        event: {
          ...state.event,
          modules: payload.isCreate
            ? state.event.modules.filter(el => el.type.label !== payload.labelToDelete)
            : state.event.modules
        }
      };

    case TYPES.REMOVE_MODULE.SUCCESS:
      const nextModules = state.event.modules.filter(el => el.type.label !== payload.type.label);
      const isDeletingActive = state.activeModulesLabels.includes(payload.type.label);

      return {
        ...state,
        activeModulesLabels: isDeletingActive
          ? nextModules.length
            ? nextModules[0].type.label
            : ''
          : state.activeModulesLabels,
        event: {
          ...state.event,
          modules: nextModules
        }
      };

    case TYPES.REMOVE_ALL_MODULES:
      return {
        ...state,
        activeModulesLabels: [],
        event: {
          ...state.event,
          modules: []
        }
      };

    case TYPES.SET_ACTIVE_MODULE_LABEL:
      return {
        ...state,
        activeModulesLabels: payload
      };

    case TYPES.SET_ACTIVE_MODULES_LABELS:
      return {
        ...state,
        activeModulesLabels: state.activeModulesLabels?.includes(payload)
          ? state.activeModulesLabels?.filter(el => el !== payload)
          : [...state.activeModulesLabels, payload]
      };

    case TYPES.SET_IS_EDITING_MODULES:
      return {
        ...state,
        isEditingModules: payload
      };

    case TYPES.ADD_MODULE.START:
      return {
        ...state,
        event: {
          ...state.event,
          modules: [
            ...state.event.modules?.filter(el => el.type.id !== payload.mod.type.id),
            payload.mod
          ]
        },
        isSaving: payload.isCreate ? false : true
      };

    case TYPES.ADD_MODULE.SUCCESS:
      return {
        ...state,
        event: {
          ...state.event,
          modules: [...state.event.modules.filter(el => el.type.id !== payload.type.id), payload]
        },
        isSaving: false
      };

    case TYPES.ADD_MODULE.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          modules: state.event.modules.filter(el => !el.temporary)
        },
        isSaving: false
      };

    case TYPES.EDIT_EVENT.START:
      return {
        ...state,
        isSaving: true
      };

    case TYPES.EDIT_EVENT.SUCCESS:
    case TYPES.EDIT_EVENT.ERROR:
      return {
        ...state,
        event: {
          ...state.event,
          savedItineraryPort: payload.itinerary_port ?? state.event?.itinerary_port,
          savedVessel: payload.vessel ?? state.event?.vessel
        },
        isSaving: false
      };
    case TYPES.GET_EVENT.START:
      return {
        ...state,
        loading: true
      };

    case TYPES.GET_EVENT.SUCCESS:
      const port_type = payload.itinerary_port ? 'itinerary_port' : 'port';

      return {
        ...state,
        loading: false,
        event: {
          ...state.event,
          ...payload,
          port_type,
          port: port_type === 'itinerary_port' ? null : payload.port,
          modules: _sortBy(payload.modules, ['sort_index']),
          department_ids: payload.departments ? payload.departments.map(el => el.id) : [],
          savedItineraryPort: payload.itinerary_port,
          savedVessel: payload.vessel
        },
        activeModulesLabels: state.activeModulesLabels?.length
          ? state.activeModulesLabels
          : payload.modules.map(module => module.type.label)
      };

    case TYPES.GET_EVENT.ERROR:
      return {
        ...state,
        loading: false
      };
    case TYPES.RESET_EVENT_STATE:
      return {
        ...INITIAL_STATE,
        event: INITIAL_EVENT,
        errors: INITIAL_ERRORS
      };

    case TYPES.SET_EVENT_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };
    case TYPES.SET_EVENT_VALUE:
      return {
        ...state,
        event: {
          ...state.event,
          ...payload
        }
      };

    case TYPES.CREATE_EVENT.START:
      return {
        ...state,
        isCreating: true
      };

    case TYPES.CREATE_EVENT.SUCCESS:
    case TYPES.CREATE_EVENT.ERROR:
      return {
        ...state,
        isCreating: false
      };

    case TYPES.SET_IS_MULTIPLE_EVENTS_CREATE:
      return {
        ...state,
        event: {
          ...state.event,
          isMultipleEventsCreate: payload
        }
      };

    case TYPES.RESET_MULTIPLE_EVENTS_DATES:
      return {
        ...state,
        event: {
          ...state.event,
          dates: []
        }
      };

    case TYPES.RESET_SINGLE_EVENT_DATES:
      return {
        ...state,
        event: {
          ...state.event,
          date_type: 'single',
          started_at: undefined,
          ended_at: undefined
        }
      };

    case TYPES.REORDER_MODULES: {
      const { oldIndex, newIndex } = payload;
      const reorderedModules = arrayMove(state.event?.modules, oldIndex, newIndex);

      return {
        ...state,
        event: {
          ...state.event,
          modules: reorderedModules
        }
      };
    }

    case `${setEventWatchers.fulfilled}`: {
      const { watchers } = payload;

      return {
        ...state,
        event: {
          ...state.event,
          watchers
        }
      };
    }

    case LOCATION_CHANGE: {
      if (!payload.location.pathname.includes(`${paths.events}/`)) {
        return {
          ...INITIAL_STATE,
          event: INITIAL_EVENT,
          errors: INITIAL_ERRORS
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
