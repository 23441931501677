import { FC } from 'react';
import { PurchasingRequisitionStatusBase, Supplier } from '@/common/types/purchasing.ts';
import { checkIfActionIsVisible } from '@/common/components/purchasing/requisition/suppliers/supplier/layout/right/helpers.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { supplierHasValidApprovedQuantities } from '@/common/components/purchasing/requisition/store/selectors-ts';
import ExportPdf from 'common/components/general/ExportPdf';
import FileRemoveButton from '@/ts-common/components/form/inputs/upload-files/FileRemoveButton';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { shouldIncludeDefaultEmailAttachments } from '@/common/components/purchasing/requisition/store/actions.ts';
import { selectShouldIncludeDefaultEmailAttachments } from '@/common/components/purchasing/requisition/store/selectors-ts.ts';

type EmailSupplierExportsProps = {
  supplier: Supplier;
  emailStatuses: PurchasingRequisitionStatusBase[];
  isPo: boolean;
  isDownloading: boolean;
  onPdfDownload: (id: number) => void;
};

const EmailSupplierExports: FC<EmailSupplierExportsProps> = ({
  supplier,
  emailStatuses,
  isPo,
  isDownloading,
  onPdfDownload
}) => {
  const dispatch = useAppDispatch();
  const includeDefaultEmailAttachments = useAppSelector(selectShouldIncludeDefaultEmailAttachments);

  const hasValidApprovedQuantity = useAppSelector(state =>
    supplierHasValidApprovedQuantities(state, supplier.id)
  );

  if (!includeDefaultEmailAttachments) return null;

  const onAttachmentRemove = () => {
    dispatch(shouldIncludeDefaultEmailAttachments(false));
  };

  return (
    <>
      {checkIfActionIsVisible(supplier.status, emailStatuses, 'po') &&
      isPo &&
      hasValidApprovedQuantity ? (
        <Container>
          <ExportPdf
            className="h-24 me-1"
            showLabel={false}
            onClick={() => onPdfDownload(supplier.id)}
            type="pdfSquare"
            svgStyle={{ width: 13, height: 13 }}
            disabled={isDownloading}
          />
          <FileRemoveButton size={'sm'} onClick={onAttachmentRemove} />
        </Container>
      ) : null}
    </>
  );
};

export default EmailSupplierExports;

const Container = styled.div`
  position: relative;
  .file-remove-icon {
    right: ${variables.size2};
  }
  &:hover {
    .file-remove-icon {
      opacity: 1;
    }
  }
`;
