import { FC, ReactNode } from 'react';
import { Location } from 'history';
import { NavigationGroupTab } from 'common/components/buttons/NavigationGroupTabs';
import PageTitleWithTabs from 'common/components/general/PageTitleWithTabs';
import paths from 'routing/routes/_paths';
import formPlanningIcon from 'common/assets/svg/forms/forms-planning.svg';
import menuIcon from 'common/assets/svg/common/burger.svg';
import formObligationsIcon from 'common/assets/svg/common/forms-obligations.svg';
import permissions from '@/common/utils/permissions/constants';
import reports from 'common/assets/svg/common/reports.svg';
import forms from '@/common/assets/svg/common/forms.svg';

type SwitchButtonGroupProps = {
  children: ReactNode;
};

export const getTabs = (): NavigationGroupTab[] => [
  {
    to: paths.form_planning,
    isActive: (_, location: Location) => location.pathname.startsWith(paths.form_planning),
    permissions: [permissions.office.forms.planning.view, permissions.onboard.forms.view],
    icon: formPlanningIcon,
    tooltip: 'Planning'
  },
  {
    to: paths.form_obligations,
    isActive: (_, location: Location) => location.pathname.startsWith(paths.form_obligations),
    permissions: [permissions.office.forms.obligations.view, permissions.onboard.forms.view],
    icon: formObligationsIcon,
    tooltip: 'Obligations'
  },
  {
    to: `${paths.forms}`,
    permissions: [permissions.office.forms.list.view, permissions.onboard.forms.list.view],
    isActive: (_, location: Location) =>
      location.pathname.startsWith(paths.forms) &&
      !location.pathname.startsWith(paths.form_planning),
    icon: menuIcon,
    tooltip: 'List'
  },
  {
    to: `${paths.reports_forms}`,
    permissions: [permissions.office.forms.reports.view],
    isActive: (_, location: Location) => location.pathname.startsWith(paths.reports_forms),
    icon: reports,
    tooltip: 'Reports'
  },
  {
    to: `${paths.form_table_reports}`,
    permissions: [permissions.office.forms.reports.view],
    isActive: (_, location: Location) => location.pathname.startsWith(paths.form_table_reports),
    icon: forms,
    tooltip: 'Table Reports'
  }
];

const SwitchButtonGroup: FC<SwitchButtonGroupProps> = ({ children }) => {
  const tabs = getTabs();

  return <PageTitleWithTabs titleComponent={children} tabs={tabs}></PageTitleWithTabs>;
};

export default SwitchButtonGroup;
