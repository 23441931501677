import moment from 'moment';

type ClearErrorValue = (obj: unknown, isTopLevel?: boolean) => unknown;

const clearErrorValue: ClearErrorValue = (obj, isTopLevel = true) => {
  // If it's an array, process each item but don't add `error: null` to the array itself
  if (Array.isArray(obj)) {
    return obj.map(item => clearErrorValue(item, false));
  }

  // If it's an object, handle it
  if (typeof obj === 'object' && obj !== null && !moment.isMoment(obj)) {
    const newObj: Record<string, unknown> = {};
    for (const key in obj) {
      if (key !== 'error') {
        newObj[key] = clearErrorValue((obj as Record<string, unknown>)[key], false); // Recursively process the properties
      }
    }

    // Only add `error: null` if it's a top-level object
    if (isTopLevel) {
      newObj.error = null;
    }

    return newObj;
  }

  return obj;
};

export const clearStateValidationErrors = (state: Record<string, unknown>) => {
  const result: Record<string, unknown> = {};

  for (const key in state) {
    result[key] = clearErrorValue(state[key]);
  }

  return result;
};
