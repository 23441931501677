import WidgetTitle from 'views/dashboard/components/WidgetTitle';
import WidgetDefaultTable from 'views/dashboard/components/WidgetDefaultTable';
import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';
import { useSelector } from 'react-redux';
import { selectWidgetState } from 'store/dashboards/selectors';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import _get from 'lodash/get';
import moment from 'moment';
import CommentsPopover from 'common/components/popovers/CommentsPopover';

import { selectWidgetPreferences } from 'store/dashboards/selectors';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import ticketing from 'assets/svg/sidebar/ticketing.svg';
import { numberToStr } from 'common/utils/numbers';
import { constructBudgetTypeComponent } from 'views/settings/accounting/budget/helpers';
import permissions from 'common/utils/permissions/constants';

const Budget = ({ id }) => {
  const { label, ...state } = useSelector(state => selectWidgetState(state, id));
  const preferences = useSelector(state => selectWidgetPreferences(state, id));

  const categories = [
    {
      text: 'Ledger Cards',
      label: 'accounts',
      icon: accounting,
      color: 'turquoise'
    },
    {
      text: 'Purchasing',
      label: 'purchasing',
      icon: purchasing,
      color: 'coral'
    },
    {
      text: 'Ticketing',
      label: 'ticketing',
      icon: ticketing,
      color: 'royal-blue'
    }
  ];
  const budgetTypeFilter = preferences?.category;

  return (
    <div className={`widget-budget-table ${preferences?.category}`}>
      <WidgetTitle
        id={id}
        linkTo={paths.accounting_budget_settings}
        linkToPermissions={[permissions.office.settings.accounting.budget.view]}
        customLabel={categories.find(c => c.label === budgetTypeFilter)}
      />
      {budgetTypeFilter ? (
        <WidgetDefaultTable
          id={id}
          columns={[
            {
              header: 'Scenario',
              key: 'budget_scenario',
              data_key: 'budget_scenario.name',
              truncate: true,
              headerClassName: 'text-violet',
              width: 2
            },
            {
              header:
                budgetTypeFilter === 'accounts'
                  ? 'Account'
                  : budgetTypeFilter === 'purchasing'
                  ? 'Purchasing Category'
                  : 'Ticket Type',
              key: 'budgetable',
              headerClassName: 'text-violet',
              width: 2
            },
            {
              header: 'Conpany',
              key: 'budget_type',
              headerClassName: 'text-violet',
              width: 2
            },
            {
              header: 'Period',
              key: 'period',
              type: 'date',
              canFilter: false,
              headerClassName: 'text-violet',
              width: 3
            },
            {
              header: 'Budgeted Amount ($)',
              key: 'amount',
              type: 'price',
              canFilter: true,

              className: 'text-start text-violet '
            },
            budgetTypeFilter === 'purchasing'
              ? {}
              : {
                  header: 'Calculated Amount ($)',
                  type: 'price',
                  headerClassName: 'text-violet',
                  className: 'text-start text-violet',
                  key: 'calculated_amount'
                }
          ]}
          rows={{
            budgetable: data => {
              const name = _get(data, 'budgetable.name', '-') || '-';
              const code = _get(data, 'budgetable.code', '');
              const budgetableType = _get(data, 'budgetable_type');

              return (
                <div className="d-flex justify-content-between">
                  <TextWithTooltip className="d-flex align-items-center">
                    {budgetableType === null ? 'All' : `${code ? `${code}.` : ''} ${name}`}
                  </TextWithTooltip>

                  <CommentsPopover
                    comments={data?.remarks}
                    addBtn={false}
                    popoverTitle="Remarks"
                    popoverProps={{ placement: 'left' }}
                  />
                </div>
              );
            },
            budget_type: data => {
              const budgetType = constructBudgetTypeComponent(data);

              return budgetType;
            },
            amount: data => {
              return (
                <TextWithTooltip className="text-dark h-auto">
                  {numberToStr(data.amount, 2, 2)}
                </TextWithTooltip>
              );
            },
            calculated_amount: data => {
              return (
                <TextWithTooltip className="text-dark h-auto">
                  {numberToStr(data.calculated_amount, 2, 2)}
                </TextWithTooltip>
              );
            },
            period: data => {
              const period_end = _get(data, 'period_end', '-');
              const period_start = _get(data, 'period_start', '-');
              const period_type = _get(data, 'period_type', '-');

              return (
                <div>
                  <span className="fw-bold text-capitalize cme-2">
                    {period_type === 'c' ? 'custom' : period_type === 'y' ? 'year' : period_type}:{' '}
                  </span>
                  <span>
                    {moment(period_start).format('DD/MM/YYYY')} -
                    {moment(period_end).format('DD/MM/YYYY')}
                  </span>
                </div>
              );
            },
            remarks: data => {
              const remarks = _get(data, 'remarks');

              return (
                <CommentsPopover
                  comments={remarks}
                  addBtn={false}
                  popoverTitle="Remarks"
                  popoverProps={{ placement: 'left' }}
                />
              );
            }
          }}
          label={label}
          state={state}
        />
      ) : null}
    </div>
  );
};

Budget.propTypes = {
  id: PropTypes.number
};

export default Budget;
