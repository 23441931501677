import { useRef, useState, FC, PropsWithChildren, useCallback } from 'react';
import { Tooltip } from 'reactstrap';
import type { Placement } from '@popperjs/core';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

type TextWithTooltipProps = PropsWithChildren<{
  placement?: Placement;
  className?: string;
  innerTooltipClassName?: string;
  dataCy?: string;
  allowOverflow?: boolean;
  onClick?: () => null;
}>;

const TextWithTooltip: FC<TextWithTooltipProps> = ({
  children,
  placement = 'top',
  className = '',
  onClick = () => null,
  innerTooltipClassName = '',
  dataCy = undefined,
  allowOverflow = false
}) => {
  const parentElement = useRef<HTMLDivElement>(null);
  const overflownElement = useRef<HTMLDivElement>(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { avoidRender, tooltipID } = useTooltipID('text-with-tooltip');

  const isOverflown = useCallback(() => {
    if (!overflownElement.current) return false;

    return overflownElement.current.scrollWidth > overflownElement.current.clientWidth;
  }, []);

  const handleMouseOver = useCallback(() => {
    // Check for opened Drawers
    const openedDrawer = document.getElementById('drawer-container')?.querySelector('.drawer.open');

    if (openedDrawer && tooltipID) {
      const isContained = openedDrawer.contains(parentElement.current);
      // If Text is not in the Opened Drawer, we don't show the Tooltip

      if (isContained) {
        setShowTooltip(isOverflown());
      } else {
        setShowTooltip(false);
      }
    } else if (tooltipID && document.getElementById(tooltipID)) {
      setShowTooltip(isOverflown());
    } else {
      setShowTooltip(false);
    }
  }, [isOverflown, tooltipID]);

  return (
    <div
      className={`text-overflow h-100p w-100p overflow-hidden ${className}`}
      ref={parentElement}
      onMouseOut={() => setShowTooltip(false)}
      onMouseOver={() => handleMouseOver()}
      data-testid="text-overflow"
    >
      <div
        onClick={() => {
          setShowTooltip(false);
          onClick();
        }}
        className={`text-overflow__text w-100p ${!allowOverflow ? 'text-truncate' : ''}`}
        ref={overflownElement}
        id={tooltipID}
        data-cy={dataCy}
      >
        {children}
      </div>
      {!avoidRender && !!tooltipID && showTooltip ? (
        <Tooltip
          boundariesElement="window"
          arrowClassName="d-none"
          placement={placement}
          target={tooltipID}
          innerClassName={innerTooltipClassName}
          fade={false}
          isOpen={showTooltip}
        >
          {children}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default TextWithTooltip;
