import _isNumber from 'lodash/isNumber';

export const numberToStr = (
  number?: string | number | null,
  decimalScale?: number,
  minDecimalFraction?: number
): string => {
  if (!number && number !== 0) return '-';

  let formattedNumber: number | string = number;

  if (typeof number === 'string' && number.includes(',')) {
    // replace commas in numbers that are formatted as strings
    formattedNumber = Number(number.replace(/[^\d.-]/g, ''));
  }

  formattedNumber = parseFloat(formattedNumber as string);

  return !isNaN(formattedNumber)
    ? formattedNumber.toLocaleString('en', {
        minimumFractionDigits: minDecimalFraction !== undefined ? minDecimalFraction : 0,
        maximumFractionDigits: decimalScale !== undefined ? decimalScale : 2
      })
    : '-';
};

export const strToNumber = (formattedStr?: string | number | null): number | null => {
  if (_isNumber(formattedStr)) return formattedStr;
  if (!formattedStr || formattedStr.length === 0) return null;
  const numberValue = Number(formattedStr.replace(/[^\d.-]/g, ''));

  if (isNaN(numberValue)) return null;

  return numberValue;
};

export const hasValue = (value: any): boolean => value || value === 0;

export const toIntegerIfWhole = (num: number | null | undefined) => {
  if (!hasValue(num)) return num;

  // Check if the number is a whole number (has only trailing zeroes)
  if (num !== null && num !== undefined && (Number.isInteger(num) || num % 1 === 0)) {
    return Math.trunc(num); // Convert to integer by removing the decimal
  }

  return num; // Return as is if there are non-zero decimal values
};
